import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpHeaders } from '@angular/common/http';
import { api } from '../../../environments/api';

@Injectable()
export class BaseService {
  constructor(private httpService: HttpService) {}

  /*
   * Clear Empty Data Fields
   * */

  clearEmpty(data: any) {
    for (let propName of Object.keys(data)) {
      if (data[propName] == null || data[propName] === undefined) {
        delete data[propName];
      }
    }
    return data;
  }

  /*
   * Post Data Suggestion Address
   * */

  postSuggestion(query) {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + api.dadataApi,
    });

    return this.httpService.postCustom(
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/' +
        'address',
      {
        query: query,
        count: 1,
      },
      headers
    );
  }
}
