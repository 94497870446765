import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ClinicService } from '../../services/clinic.service';

@Component({
  selector: 'app-sidebar-clinic',
  templateUrl: './sidebar-clinic.component.html',
  styleUrls: ['./sidebar-clinic.component.scss'],
})
export class SidebarClinicComponent implements OnInit {
  constructor(
    private userService: UserService,
    public clinicService: ClinicService
  ) {}

  ngOnInit() {}

  closeMobileSidebar() {
    this.userService.showMobileSidebar = false;
    this.userService.showMobileOverlay = false;
  }

  closeMobileSidebarCheck() {
    this.userService.showMobileSidebar = this.userService.showMobileSidebar
      ? false
      : null;
    this.userService.showMobileOverlay = this.userService.showMobileOverlay
      ? false
      : null;
  }
}
