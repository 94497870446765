import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { AppointmentsTimeGridInterface } from '../../../interfaces/appointments/appointments-time-grid.interface';
import { CreateAppointmentDialogComponent } from '../create-appointment-dialog/create-appointment-dialog.component';
import { api } from '../../../../../environments/api';

@Component({
  selector: 'app-appointment-add-timegrid-dialog',
  templateUrl: './appointment-add-timegrid-dialog.component.html',
  styleUrls: ['./appointment-add-timegrid-dialog.component.scss'],
})
export class AppointmentAddTimegridDialogComponent implements OnInit {
  cells: AppointmentsTimeGridInterface[] = this.dialogData.item;
  date = this.dialogData.date;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AppointmentAddTimegridDialogComponent>
  ) {}

  ngOnInit() {
    !api.production ? console.log('time-cells', this.cells) : null;

    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close({
        key: this.dialogData.key,
        date: this.dialogData.date,
      });
    });
  }

  showCreateAppointment(item, index) {
    const dialogRef = this.dialog.open(CreateAppointmentDialogComponent, {
      data: {
        item: item,
        index: index,
      },
      panelClass: 'record-clinic-dialog',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((response) => {
      !api.production ? console.log('create-dialog-result', response) : null;

      if (response.success) {
        this.cells[index].IsEnable = false;
      }
    });
  }

  closeDialog() {
    this.dialogRef.close({
      key: this.dialogData.key,
      date: this.dialogData.date,
    });
  }
}
