import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../shared/services/user.service';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { api } from '../../../environments/api';
import { Subscription } from 'rxjs';
import { UserInterface } from '../../shared/interfaces/user/user.interface';
import { FindUserResponseInterface } from '../../shared/interfaces/user/find-user/find-user-response.interface';
import { AddUserEventResponseInterface } from '../../shared/interfaces/auth/add-user-event-response.interface';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss'],
})
export class RecoverComponent implements OnInit {
  form: FormGroup;
  errorMessage: string = api.mainErrorMessage;
  errorTitle: string = api.mainErrorTitle;
  preloaderVisibility: boolean = true;
  timeout: boolean = true;
  recoverSubscribe: Subscription;
  checkEmailSubscribe: Subscription;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (localStorage.getItem('user-key')) {
      this.router.navigate(['/account']);
      return;
    } else {
      this.preloaderVisibility = false;
    }

    this.form = new FormGroup({
      emailPhone: new FormControl('', [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]),
    });
  }

  onSubmit() {
    this.timeout = true;

    this.preloaderVisibility = true;

    this.checkEmailSubscribe = this.userService
      .findUserEmail(this.form.value.emailPhone)
      .subscribe((response: FindUserResponseInterface) => {
        if (response.FindUserResult.Success) {
          const userData = response.FindUserResult.UserData as UserInterface;

          const userId = userData.UserId;
          this.recoverSubscribe = this.authService
            .recover(userId)
            .subscribe((response: AddUserEventResponseInterface) => {
              !api.production
                ? console.log('Restore Password:', response)
                : null;

              this.timeout = false;

              if (response.AddUserEventResult.Success) {
                this.openDialog(
                  'Успешная отправка!',
                  'Вам на почту была выслана ссылка для востановления пароля. <br> Пожалуйста воспользуйтесь ей для востановления доступа к аккаунту.'
                );
              } else {
                this.openDialog(this.errorTitle, this.errorMessage, true);
              }
            });
        } else {
          this.timeout = false;
          this.openDialog(
            'Несуществующий аккаунт!',
            'Указанный емайл не был зарегистрирован ранее. Проверьте правильность емайла или обратитесь в службу поддержки',
            true
          );
        }
      });

    setTimeout(() => {
      if (this.timeout) {
        this.openDialog(this.errorTitle, this.errorMessage, true);
        this.recoverSubscribe.unsubscribe();
      }
    }, 30000);
  }

  openDialog(title: string, message: string, error: boolean = false) {
    const dialogRef = this.dialog.open(RecoverDialog, {
      data: {
        title: title,
        message: message,
        error: error,
      },
    });

    if (error) {
      dialogRef.afterClosed().subscribe(() => {
        this.preloaderVisibility = false;
      });
    } else {
      dialogRef.afterClosed().subscribe(() => {
        this.preloaderVisibility = false;
        this.router.navigate(['/auth/sign-in']);
      });
    }
  }
}

@Component({
  selector: 'recover-dialog',
  templateUrl: 'recover-dialog.html',
})
export class RecoverDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}
}
