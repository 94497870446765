import { SelectInterface } from '../interfaces/select.interface';

export const clinicMetro: SelectInterface[] = [
  { value: '0535A4D2-57FF-4CF8-B0A5-9F202EE365E4', name: 'Авиамоторная' },
  {
    value: 'E08CA65A-B190-4252-A018-645C852E194B',
    name: 'Автозаводская (14 линия)',
  },
  {
    value: '107DBF22-59B7-4295-8D64-E8C4EAA66A9B',
    name: 'Автозаводская (Замоскворецкая линия)',
  },
  { value: 'D804CCC0-FC46-400B-B72F-EBDE8E697D37', name: 'Академическая' },
  { value: '9B3B9ECE-BB79-4041-A2EF-CC4EC317B2EC', name: 'Алексеевская' },
  { value: 'A5D89FC5-F2E0-41A7-AB05-2D58E9278EEE', name: 'Алма-Атинская' },
  { value: '1ACA5D9E-2E8F-44CD-BE6E-F7DC3CD80387', name: 'Алтуфьево' },
  { value: 'BCE1384E-3FD2-4001-8A10-F2E1CCFE818E', name: 'Андроновка' },
  { value: 'ED199BE4-9B61-45E4-AED9-E8C5D75CE63C', name: 'Аннино' },
  {
    value: '6CFFEC9F-764F-4BD4-A960-EE249DF4806F',
    name: 'Арбатская (Арбатско-Покровская линия)',
  },
  {
    value: '113E86F0-ECAC-43D4-B444-417DBB624D71',
    name: 'Арбатская (Филевская линия)',
  },
  { value: '3EEB95F0-592A-4C82-BFBE-596503EB71A2', name: 'Аэропорт' },
  { value: '6112596C-8552-4C4D-820D-C261A9C57EAF', name: 'Бабушкинская' },
  { value: 'D9D5F933-16CD-486A-B48D-095D36D329AB', name: 'Багратионовская' },
  { value: 'FAF24768-25E7-45EC-B47F-CE879ED0129F', name: 'Балтийская' },
  { value: '81395680-B023-440C-9EF8-6231D4F76681', name: 'Баррикадная' },
  { value: 'A6FCFF18-E4F9-4D6C-9928-0A0A2044897E', name: 'Бауманская' },
  { value: '8207A9E7-73A3-436B-B483-585B132839F3', name: 'Беговая' },
  { value: '7E7BE8A5-D9D3-4A2B-8E8A-03AD53A2C364', name: 'Белокаменная' },
  { value: '40E876C3-FCB1-4CDB-B373-9916860625CF', name: 'Белорусская' },
  { value: 'BCBA171F-308B-42BE-B651-E8B54834145E', name: 'Беляево' },
  { value: '37C6BA2E-FB44-455A-9374-EE081EEDCF7B', name: 'Бибирево' },
  {
    value: '2ECBAB88-5574-451A-8208-A20259623451',
    name: 'Библиотека им. Ленина',
  },
  { value: 'C3714BB4-6C81-4CE0-A039-1824EC97FCBB', name: 'Битцевский парк' },
  { value: '35805388-6884-43D4-920F-9973CB47949E', name: 'Борисово' },
  { value: '13F057B7-6B3D-491A-9B9C-F3D86F60C625', name: 'Боровицкая' },
  { value: '99C6D5CD-7308-4F6E-A8B4-A8269D9C4F4B', name: 'Ботанический сад' },
  {
    value: '49CDB445-CD5C-4F5F-8F45-7981411CCC64',
    name: 'Ботанический сад (14 линия)',
  },
  { value: '6CCC5A49-EEBA-4AA4-ABBD-8866031D9A01', name: 'Братиславская' },
  {
    value: 'F1B59312-D053-4A78-BF59-A1DB36578CBF',
    name: 'Бульвар Адмирала Ушакова',
  },
  {
    value: '050F325D-C89C-498D-B0CE-B76E7D5C9A39',
    name: 'Бульвар Дмитрия Донского',
  },
  {
    value: '5DD44DFC-C2A1-41F7-91A6-D15BCB7DFB1D',
    name: 'Бульвар Рокоссовского',
  },
  { value: '87084F1E-870D-4005-A613-284DD87FCA4E', name: 'Бунинская аллея' },
  { value: '63C31DBC-4CD2-4567-8246-B8665AFA2683', name: 'Бутырская' },
  { value: '5EA57175-FC39-4A31-8954-EA1087338E52', name: 'Варшавская' },
  { value: 'A4446C0D-636B-4FB3-9143-3F66D888E75C', name: 'ВДНХ' },
  { value: 'CF876D1B-7BB1-4D1B-A714-FEA153CBA9FE', name: 'Верхние Котлы' },
  { value: '215AC4A5-F910-42BE-81A9-8C0540A84C05', name: 'Владыкино' },
  {
    value: '6425EBC6-1490-4A2D-9672-61A7F7CA1067',
    name: 'Владыкино (14 линия)',
  },
  { value: 'E9D361D9-EF9F-4BC1-8D6B-5B95ACD3FF2E', name: 'Водный стадион' },
  { value: '218AE758-FCE8-45EE-A6A0-8B3816072B3A', name: 'Войковская' },
  {
    value: 'C1C3E11F-53FF-44D4-BD25-E011B2AE6F0E',
    name: 'Волгоградский проспект',
  },
  { value: 'E648BB9C-1B20-4F96-8D86-28685F75BA02', name: 'Волжская' },
  { value: 'B65AECD2-DD2E-4CC7-A7BA-12EEA107CEBE', name: 'Волоколамская' },
  { value: '528BB8FC-59B4-49D9-B9C3-0A5A9564AD89', name: 'Воробьевы горы' },
  { value: '5E052778-0EBB-468B-9F98-CF9058524EEC', name: 'Выставочная' },
  { value: 'E25B33FE-64AB-4476-8EE1-13B622F2023F', name: 'Выставочный центр' },
  { value: 'FD235892-D525-4FA7-B3D4-D7FE62FDE483', name: 'Выхино' },
  { value: 'E6D62C80-9C63-4365-83C2-F514528257A2', name: 'Деловой центр' },
  {
    value: '09B5BCB6-5FDF-4413-BFA0-221C7D12DA14',
    name: 'Деловой центр (МЦК)',
  },
  { value: '9BE05F72-C51E-48AD-AB02-7434A35C6676', name: 'Динамо' },
  { value: '14A1760D-C930-4713-BE09-F2E8C0385E36', name: 'Дмитровская' },
  { value: 'B56F790D-E8E5-4A46-8657-DC702D7CBF5F', name: 'Добрынинская' },
  { value: 'DCC71CAB-BB1B-40AE-9C24-D079A14AE162', name: 'Домодедовская' },
  { value: '9E302E43-00C4-4193-A5DE-F3B4694DF70C', name: 'Достоевская' },
  { value: '4E9A9945-7805-42C0-8EC9-3C8804BAA81E', name: 'Дубровка' },
  {
    value: '46BCF606-5A79-4474-AFDC-679AE6C1BDE7',
    name: 'Дубровка (14 линия)',
  },
  { value: 'FE0B8A0B-EE30-46FD-ADB3-15C7E6B2AA41', name: 'Жулебино' },
  { value: 'E31B6E3A-1668-45C9-A0BB-4697782E99D3', name: 'ЗИЛ' },
  { value: '86499745-264C-4069-877F-54F37A0AC3B4', name: 'Зорге' },
  { value: '3614D1B9-0844-4E2D-9A1B-451DBE1F0123', name: 'Зябликово' },
  { value: '7F9442F1-2431-4F0E-983F-874CE6CB23F9', name: 'Измайлово' },
  { value: '0245B868-4000-47FD-861D-41B0C9DD0EBE', name: 'Измайловская' },
  { value: '7C764FAF-69FF-459F-A741-98C8FD874FE6', name: 'Калужская' },
  { value: 'DF47CD44-E6B1-4032-9D09-AE2F69EFF7E5', name: 'Кантемировская' },
  { value: 'A5B62AEA-6D5D-42A2-B825-C38F69AD3249', name: 'Каховская' },
  { value: '6F82A7B6-F815-44F0-8533-19E0C9B8E5ED', name: 'Каширская' },
  { value: '52666F55-7936-4407-9989-2063DDBCC3B8', name: 'Киевская' },
  { value: 'EF45EF94-5184-40E8-A0B1-90AD577E3297', name: 'Китай-город' },
  { value: '5E344AD0-8080-4606-9279-F083DE5E2A51', name: 'Кожуховская' },
  { value: '80E19F0D-2B9B-4EAE-98DF-F40D0DA484BC', name: 'Коломенская' },
  { value: 'E0A2BE6D-4F37-4634-ACDA-903C101FA8E0', name: 'Комсомольская' },
  { value: '46FC9E98-124F-49EA-8460-16624C2921BF', name: 'Коньково' },
  { value: '67ED7D13-4D50-45F3-89FF-715D333A19A9', name: 'Коптево' },
  { value: 'F0542A37-3C95-4BED-83C1-C5AE9FDFD9C7', name: 'Котельники' },
  { value: '81E04DDD-470A-47AF-A36B-331B10DF28E9', name: 'Красногвардейская' },
  { value: 'DC1FBBFD-CF67-4DC5-A124-3AF00C424E31', name: 'Краснопресненская' },
  { value: '3D4A0416-1EDA-44A5-A33B-CB4829170105', name: 'Красносельская' },
  { value: 'A3552E9E-2654-4098-9F0B-B20590CFFE03', name: 'Красные Ворота' },
  {
    value: 'CD7C3F50-B12E-455C-BE39-195B652A29F1',
    name: 'Крестьянская застава',
  },
  { value: 'FD809F9B-E204-42BA-9EAD-5F83C3771CD4', name: 'Кропоткинская' },
  { value: 'FE67C579-9AE8-49EB-AD97-70A3EB63D0AB', name: 'Крылатское' },
  { value: '495B18BC-86F4-4261-86F9-059DD7B8E749', name: 'Крымская' },
  { value: 'C5D6E3E8-4259-47BC-B16A-E7ADA2AEF62F', name: 'Кузнецкий мост' },
  { value: '464973AB-6B40-4998-8441-C0377911D1E2', name: 'Кузьминки' },
  { value: '708EA4F3-7C4C-4E12-B7B7-B2399B62C9C1', name: 'Кунцевская' },
  { value: '9278B884-6BEC-4488-AADD-5016C355AD5A', name: 'Курская' },
  { value: '16E8AF43-CA81-4257-9440-B4AE42FD58B2', name: 'Кутузовская' },
  { value: 'FB3A63E2-47DD-4BD1-A2DB-D8F513F56C1F', name: 'Ленинский проспект' },
  {
    value: 'AA16B4BE-8BDF-44FD-BE01-D88AB6AFEDCF',
    name: 'Лермонтовский проспект',
  },
  { value: '31580562-B438-4C04-BCA3-19F5C48FE7EF', name: 'Лесопарковая' },
  { value: '6B6FBDF2-B18B-4B6E-A819-F94881601502', name: 'Лихоборы' },
  { value: '03B956F6-1F03-4B1D-BDCF-0DD1FC170EAD', name: 'Локомотив' },
  {
    value: '54FB5A76-1A29-4DF2-9449-EE65028F4A05',
    name: 'Ломоносовский проспект',
  },
  { value: 'E2C5F3D7-1789-41B7-AF41-6CC68C722B29', name: 'Лубянка' },
  { value: '2F98DF0F-AAD7-49C0-AC85-8D7CAB7EB001', name: 'Лужники' },
  { value: '25456F9D-8051-4034-98BF-26D6594B5092', name: 'Люблино' },
  { value: 'B70238FA-67DF-404E-BAAF-89A6E9A9D7FB', name: 'Марксистская' },
  { value: '7B75670A-F73B-4605-AB79-D03F08B4AEF8', name: 'Марьина роща' },
  { value: 'D15170F4-8C41-4742-BC76-6907225DD15E', name: 'Марьино' },
  { value: '4D2B088B-BCC1-43DD-8E1A-2983FDC4A99D', name: 'Маяковская' },
  { value: '5FC707F8-4F7A-4BB1-B7F7-CD9BAB483795', name: 'Медведково' },
  { value: '8EE7F06C-7D29-4E86-91A5-F1F567FD6CA0', name: 'Международная' },
  { value: '0F1A6371-4C8C-4479-8676-AD59685AF04A', name: 'Менделеевская' },
  { value: 'E555C323-3B70-49B9-9F88-4B6734E70299', name: 'Минская' },
  { value: 'B62F4D7C-BC4D-45A1-81D8-0A6196F5F8C3', name: 'Митино' },
  { value: '88758787-3189-45CE-B341-90F32E79A69F', name: 'Молодежная' },
  { value: '77E9EDC0-C96E-45EC-8F15-018B7A85F52A', name: 'Мякинино' },
  { value: 'A6A26B7F-F902-4821-9F61-1B21860B11A4', name: 'Нагатинская' },
  { value: '689DEB76-C389-4CF2-A1FA-1DA32DDA5DDD', name: 'Нагорная' },
  {
    value: '2E0126EF-BEB9-446C-ACAF-91FBD9335199',
    name: 'Нахимовский проспект',
  },
  { value: '35962448-65CA-45F1-8FDB-6F4BAE827E80', name: 'Нижегородская' },
  { value: '16E2FD99-2CEB-49B3-934A-93CB4A200641', name: 'Новогиреево' },
  { value: 'AF74EBFA-5270-421D-AAD2-A50E3C1FDCC9', name: 'Новокосино' },
  { value: '6A85D2B4-8368-453A-BFC0-4A88179DAE95', name: 'Новокузнецкая' },
  { value: 'C108A9EC-DF57-490F-A321-0C8856F425ED', name: 'Новослободская' },
  { value: 'CE379A11-2F91-4E29-9224-F72A03D5D7C5', name: 'Новохохловская' },
  { value: '1E78FC1F-A1AC-4FA8-AF39-7E333FB965F0', name: 'Новоясеневская' },
  { value: '1BA9DD8A-30C2-43B5-B967-0FB51363DDA1', name: 'Новые Черемушки' },
  { value: '853E1644-5BC8-4167-9265-E5B415B55D6C', name: 'Окружная' },
  { value: 'AD88E7F0-9CC0-487F-9FE2-E7A427F151E0', name: 'Октябрьская' },
  { value: '55A91FBA-477A-4208-BBF1-96130C2A9C93', name: 'Октябрьское поле' },
  { value: 'A85A3924-74C2-49D4-89E6-FAD2A21B3828', name: 'Орехово' },
  { value: '6BFD1097-98F3-4A5A-9F97-231FBE8B12B8', name: 'Отрадное' },
  { value: 'FA90CDBC-AE9B-4288-B3BA-CE624931CFA4', name: 'Охотный ряд' },
  { value: 'F966B25B-833B-49E5-93AA-27ABA2DDACE8', name: 'Павелецкая' },
  { value: 'F05FE198-613A-4EDE-BC8E-33C4D1470C97', name: 'Панфиловская' },
  { value: '61801D73-B572-4E9C-B586-6273CC4E048F', name: 'Парк Культуры' },
  { value: '50010E52-E076-4203-92DF-EE15E3B34EC4', name: 'Парк Победы' },
  { value: 'D7775324-18F0-4511-A8F3-1B70ED42C8E6', name: 'Партизанская' },
  { value: '5C4ABA4E-4A4E-4EA0-91A7-2A90605481CD', name: 'Первомайская' },
  { value: '184DA23F-AF1D-4058-90A0-842A23347CA7', name: 'Перово' },
  { value: '4FF61393-9F29-46BF-BD8F-D74AE4717F6D', name: 'Петровский парк' },
  {
    value: '64E37329-567B-4160-9DCE-21F9CFD76FDA',
    name: 'Петровско-Разумовская',
  },
  { value: '8668D32D-4CE8-4D49-8B4F-BB66D3E969F4', name: 'Печатники' },
  { value: '1365A07E-6F83-4029-B2C3-9F9F6D26CE05', name: 'Пионерская' },
  { value: 'EE48297C-627A-483F-B1AF-50ACC693C17A', name: 'Планерная' },
  { value: 'E92ED61F-EE40-4A51-A5DF-5E1BA6F5796A', name: 'Площадь Гагарина' },
  { value: '7B080908-4DBA-4B9D-8FA2-C564BE2F32F1', name: 'Площадь Ильича' },
  { value: '46BE3020-13A4-4E05-997C-EB71EEC0EADA', name: 'Площадь Революции' },
  { value: 'F906774C-03E4-4C3B-AB10-11294E98F382', name: 'Полежаевская' },
  { value: '2C518E58-1900-49CC-A91E-D5B239148D9F', name: 'Полянка' },
  { value: '74238A53-0AFE-4496-81F1-D710357DDC7E', name: 'Пражская' },
  {
    value: '4F9B6824-221C-4E66-8E02-052F271CBE80',
    name: 'Преображенская площадь',
  },
  { value: '78A830FB-9EF2-4ADD-ADF0-3E302823A879', name: 'Пролетарская' },
  {
    value: '35C1AB07-3053-485D-B969-1B741E15C564',
    name: 'Проспект Вернадского',
  },
  { value: 'EB452839-F3E1-4732-8FB8-F26B2FFA66AF', name: 'Проспект Мира' },
  { value: 'C3EA9E96-9DC4-46C1-A32B-27549B783F19', name: 'Профсоюзная' },
  { value: '36393396-4AF3-4481-AF69-C1E938EF77FE', name: 'Пушкинская' },
  { value: 'E35032C4-96C1-4A35-96FF-9EFE23CCC5E5', name: 'Пятницкое шоссе' },
  { value: '359817B7-FD02-419A-9B46-83E2DD0D9CED', name: 'Раменки' },
  { value: '25786835-0F97-4247-A533-9E87541B5443', name: 'Речной вокзал' },
  { value: '609D7C2B-4340-46B8-A24B-E3FF43A3031D', name: 'Рижская' },
  { value: '675C0D3A-1367-44AC-ABA0-437914FC82DE', name: 'Римская' },
  { value: '79C42B15-18FB-473A-B61D-F9A50E551B0F', name: 'Ростокино' },
  { value: '3BB8A692-B383-4AB1-AF16-EFB026442AC7', name: 'Румянцево' },
  { value: '3D299B4A-A268-4E53-AFFE-E95DC8311387', name: 'Рязанский проспект' },
  { value: '90C4D107-12D3-403D-A8F2-E1B235B70C65', name: 'Савеловская' },
  { value: '2B599EFF-7D41-41EA-97E2-1D4EF4447857', name: 'Саларьево' },
  { value: '419574CA-C0D7-4CDC-BDBB-D306CE14EEEC', name: 'Свиблово' },
  { value: '997B65E5-59F9-41A4-8BC6-088F11A709B3', name: 'Севастопольская' },
  { value: 'E83DE5B8-3B9B-4D05-8A9F-5CB975A4F603', name: 'Семеновская' },
  { value: '3FD1F313-91DC-45E8-ADD5-F851A2A4BD11', name: 'Серпуховская' },
  { value: 'D548DDA9-3804-457C-AD21-7488712EDF75', name: 'Славянский бульвар' },
  {
    value: '08A6F2B6-79B5-4EDE-B64A-1BD4320D6C1E',
    name: 'Смоленская (Арбатско-Покровская линия)',
  },
  {
    value: '653824F3-2FDA-485B-BCC2-4EEE933C93FC',
    name: 'Смоленская (Филевская линия)',
  },
  { value: '80161793-DC52-45A6-A056-9C4F0C1765BC', name: 'Сокол' },
  { value: '4EB3A0BF-F56A-4145-B4A1-D193A0CCF085', name: 'Соколиная гора' },
  { value: 'AC3D67CF-0FCA-4A39-826A-3A4C667EF99C', name: 'Сокольники' },
  { value: '8F2AF9FA-3366-472A-B594-5D8829520910', name: 'Спартак' },
  { value: '0953D6D3-DEDB-41D5-A358-0124610CF53A', name: 'Спортивная' },
  { value: '0B12AC3A-7702-4760-9B0F-9C02C2262FAA', name: 'Сретенский бульвар' },
  { value: 'A6445DEA-8EEF-46A6-B1F4-735F80EEA587', name: 'Стрешнево' },
  { value: '98360F59-2542-4C7D-BC56-C4FF885C3EF3', name: 'Строгино' },
  { value: '09B963C2-C02B-4E9B-9CC0-0194B89D501F', name: 'Студенческая' },
  { value: '8E9F74A1-4FA7-4543-BF44-53AD076F0FBA', name: 'Сухаревская' },
  { value: 'E9418AC1-CC7E-4671-A486-E07AD8FD460A', name: 'Сходненская' },
  { value: '973A7013-885F-4627-AFB2-E5A23A1BAC05', name: 'Таганская' },
  { value: 'E771E34E-E210-482F-BB95-7E253BA52D32', name: 'Тверская' },
  { value: '7F01F228-177C-4496-A5BF-D241C5446567', name: 'Театральная' },
  { value: '2899F6FB-3248-48CC-AC81-C3685E1CB56D', name: 'Текстильщики' },
  { value: '36A0BC5E-492B-49DB-8967-A7C17FA7AF26', name: 'Телецентр' },
  { value: 'C8E6EDA6-C260-48DA-AA97-A98095A61CCB', name: 'Теплый Стан' },
  { value: '6BF15031-6046-4E57-82A3-02087CB9856A', name: 'Технопарк' },
  { value: 'E68F49BD-9972-42EC-A5A3-DC45177B8E81', name: 'Тимирязевская' },
  { value: 'EEE4F7A0-E6DE-4CA4-8A42-D7B106D23F36', name: 'Третьяковская' },
  { value: '2B54DE51-C39C-4FAC-B5B8-B676C8593742', name: 'Тропарево' },
  { value: '8C8981D6-CD4B-4304-8EA6-F32609D0009B', name: 'Трубная' },
  { value: 'CE662A00-616B-4E39-8C82-B398F1017295', name: 'Тульская' },
  { value: '78139FAD-A8CB-472F-BD97-672BB8825C3F', name: 'Тургеневская' },
  { value: 'B6C3AF46-814B-4065-81D7-B77C5CAE8485', name: 'Тушинская' },
  { value: 'FB6859B3-348F-4617-BFD4-32573FD446F5', name: 'Угрешская' },
  { value: '3441C8AC-4928-4A42-A361-C9BEE33D4C52', name: 'Улица 1905 года' },
  {
    value: '9E36FC6E-AF98-4B50-8180-CCCB672C8CD7',
    name: 'Улица Академика Королёва',
  },
  {
    value: '0D665D99-4C9B-4716-A898-C3FDA3417FCE',
    name: 'Улица Академика Янгеля',
  },
  { value: '6C33C287-C955-4E3F-BBEA-CB39495EED4D', name: 'Улица Горчакова' },
  { value: '2FDA912E-2409-419A-9519-B155A3EE2725', name: 'Улица Милашенкова' },
  {
    value: '98608B40-3961-4A6B-859F-F1990B14581F',
    name: 'Улица Сергея Эйзенштейна',
  },
  { value: 'DA0E8A73-F37F-4C9C-B675-018C0342638F', name: 'Улица Скобелевская' },
  {
    value: '13426CE0-99EF-47EA-8F2A-B4EA1DE622C1',
    name: 'Улица Старокачаловская',
  },
  { value: '82F4B35B-05D0-4D5A-A130-67ECB41569A2', name: 'Университет' },
  { value: '483FA3FB-2E45-40F3-92EA-153BBFEE47BF', name: 'Филевский парк' },
  { value: '86429DA3-FA80-462A-9171-FA4868FAA6FE', name: 'Фили' },
  { value: '98496478-59A3-4EC2-A5B3-805AFE8F1392', name: 'Фонвизинская' },
  { value: 'F6AD28F2-9409-4CB0-BE6E-92B3F7C844CB', name: 'Фрунзенская' },
  { value: '81A61427-4584-4225-9F58-0ED33DC9F91F', name: 'Хорошево' },
  { value: '103E1DC6-A668-4D4E-B620-BB4C506FB893', name: 'Хорошевская' },
  { value: 'F2501982-56BE-4C71-9273-C3DEE20C1BF0', name: 'Царицыно' },
  { value: 'BEBC3179-722C-40CF-B8AB-A70553083FD7', name: 'Цветной бульвар' },
  { value: '1D08A4DA-7248-478C-B28F-DF437752FDBC', name: 'ЦСКА' },
  { value: '7BD2650A-1F49-4BF0-A526-EF3E418CD265', name: 'Черкизовская' },
  { value: '3F1B6B7A-B33E-488F-8705-F729BFFA33BF', name: 'Чертановская' },
  { value: 'C0009ECA-50B4-4D33-A7C3-DC2BA148B3DF', name: 'Чеховская' },
  { value: 'ACF81E90-58A4-467E-95B4-C217ECA5B3C8', name: 'Чистые пруды' },
  { value: '98F27D35-C03E-4870-B93A-6B2548CA49F3', name: 'Чкаловская' },
  { value: '28AF7830-8C2C-4316-B34E-C45368A2F79E', name: 'Шаболовская' },
  { value: '28970FC0-DEEC-45F6-9C28-2B02E2EB4457', name: 'Шелепиха' },
  { value: 'BA00930A-BC19-45AE-B130-21827E981CEA', name: 'Шипиловская' },
  { value: '9ACB5A75-4896-4293-9D9D-6A65DFF23001', name: 'Шоссе Энтузиастов' },
  { value: 'D6572244-D3B3-4248-BD2C-961CCF961AE5', name: 'Щелковская' },
  { value: '1247624C-CDCC-4FD7-A429-47DB9A50436D', name: 'Щукинская' },
  { value: '52238D3B-A4DB-4DB3-9C5D-4035B77C766B', name: 'Электрозаводская' },
  { value: '688C7AF5-CFD4-453C-98AD-934B4A41068B', name: 'Юго-Западная' },
  { value: 'D6B13953-BD5D-4B46-A015-4A78A5CF3B55', name: 'Южная' },
  { value: '9B9479E1-0665-4118-B7DB-CB520D0EFCBF', name: 'Ясенево' },
];

export const clinicServices: SelectInterface[] = [
  { value: 'a5d3e6d6-5250-4bec-953b-d3ef08d15f25', name: 'Бронхоскопия' },
  {
    value: '4a5dc745-d24e-4f7f-9911-b23c21810912',
    name: 'Вакцинация от бешенства',
  },
  {
    value: 'e45d6dda-cdf9-4d49-a061-ea185005d5f0',
    name: 'Вакцинация против лейкоза',
  },
  {
    value: 'eaa9bbbd-92e3-4401-9fc2-82d8f459ffb3',
    name: 'Вакцинация против лептоспироза',
  },
  {
    value: 'b5a8bece-3c44-408a-8d2e-fd61269da729',
    name: 'Вакцинация против пироплазмоза',
  },
  {
    value: '65b70bc2-5bfe-45ce-8c05-a392b2b3af9b',
    name: 'Вакцинация против хламидиоза',
  },
  { value: '710a8057-46e3-48e9-b8e7-ee2206493347', name: 'Гастроскопия' },
  { value: 'ecd82e52-9588-405a-bca7-69ef9844b930', name: 'Измерение давления' },
  { value: 'bebb2c52-f9c8-480e-958c-10d6102b001d', name: 'Кастрация кобеля' },
  {
    value: '0d91b175-1573-480f-b624-2aa16ebe5fb2',
    name: 'Кастрация кобеля крипторха',
  },
  { value: 'f69ac699-9161-46ce-bc68-bd5540d3f519', name: 'Кастрация кота' },
  { value: '376b5d42-be90-4980-b8a1-9422bde53d9c', name: 'Колоноскопия' },
  {
    value: '38ada95d-f440-4c64-b96f-38d659eb29c8',
    name: 'Комплексная вакцинация кошек',
  },
  {
    value: 'a0bd872f-b962-4f41-b00e-2dfeda057ff7',
    name: 'Комплексная вакцинация собак',
  },
  {
    value: '6639bada-ee60-489f-8db9-8982589fd823',
    name: 'Компьютерная томография',
  },
  {
    value: 'fbe5e48b-b589-4b49-ae55-1c10fc10071d',
    name: 'Консультация ветеринара',
  },
  {
    value: 'da00423b-47d6-45b5-8976-6592b2b406ce',
    name: 'Консультация дерматолога',
  },
  {
    value: '52f6b687-e835-4cb3-80cf-0c9c8efca652',
    name: 'Консультация кардиолога',
  },
  {
    value: '57d041d1-b943-4c65-8d68-d73945d64b90',
    name: 'Консультация невролога',
  },
  {
    value: 'e55404fa-c7cb-4d25-902f-8c62d1cc7f27',
    name: 'Консультация онколога',
  },
  {
    value: '64033371-083a-4fd0-824f-f2d868e6e35c',
    name: 'Консультация ортопеда',
  },
  {
    value: 'be1ca714-ccc5-4d01-8c96-e0fa9d195f69',
    name: 'Консультация офтальмолога',
  },
  {
    value: '0c0aaaa5-eabf-4bdf-8fe5-0404dae3f599',
    name: 'Консультация по результатам анализов',
  },
  {
    value: 'c84eb25d-9287-42e0-a92a-230bbc64f331',
    name: 'Консультация репродуктолога',
  },
  {
    value: '39be2986-fcb6-4096-955b-b9bb6cb4f82f',
    name: 'Консультация стоматолога',
  },
  {
    value: 'e0a76e57-beed-4453-8fda-7e1a2e0d305d',
    name: 'Консультация эндокринолога',
  },
  {
    value: 'b27febb4-de42-4aec-b196-ee9edc5f4168',
    name: 'Люминесцентная диагностика',
  },
  { value: 'bfbba779-38d8-4b93-a25b-0b6ac4a53ced', name: 'Офтальмоскопия' },
  { value: '153f1e5b-009a-4312-bfb5-71d3fd029fef', name: 'Передержка кошки' },
  { value: '6c9dd049-c4b8-4f84-b457-9d8504ac6676', name: 'Передержка собаки' },
  { value: '484667ec-2b6e-4038-a6b8-fb0edad40231', name: 'Рентген' },
  { value: 'd653fbe1-d061-4cb8-976b-7647bd37d29f', name: 'Стерилизация кошки' },
  { value: '3ba237fb-9a8d-4a67-8587-bf7baa808fe5', name: 'Стерилизация суки' },
  { value: 'a19beed1-bef7-4466-aa4a-4cafd04e4fbb', name: 'Стрижка когтей' },
  { value: '69cbb415-b6b3-4d62-b7c5-83ad1984210c', name: 'Трахеоскопия' },
  { value: '1ef87bc6-7ca4-44b4-9d36-2c4fcdd9657b', name: 'УЗИ' },
  { value: 'e41c4e97-dca0-4dfd-a412-5a5c95c65136', name: 'Удаление клеща' },
  { value: '180288ae-9dbd-4387-a587-a173a23a432b', name: 'Чипирование' },
  { value: '4aa0f5ab-14f7-4b4b-8f71-8dbf9520b895', name: 'Чистка зубов кошке' },
  {
    value: 'af9fab52-c9d8-401d-b2d8-eb4482186fe8',
    name: 'Чистка зубов собаке',
  },
  {
    value: '13dbbcf2-2477-413f-b243-62c259ae01c1',
    name: 'Чистка параанальных желез',
  },
  { value: 'ea72b862-4ef2-4572-b442-a1468c22e7e7', name: 'Чистка ушей' },
  { value: '5ad6bc26-1fb3-4e05-b565-5b7edac3aaf4', name: 'ЭКГ' },
  { value: 'e07767fe-aa14-4306-b044-f432dffd074c', name: 'ЭКГ-мониторинг' },
];

export const clinicDirections: SelectInterface[] = [
  { value: '005233f3-eb6e-4803-bb57-07fd0c4faa21', name: 'Консультация' },
  { value: '3b2a2f97-b484-4139-998c-9507ac631411', name: 'Обследование' },
  { value: '54217d21-de34-4261-b924-56fa13331897', name: 'Другое' },
];

export const clinicActions: SelectInterface[] = [
  { value: 'a4f0a7cd-8099-4ff3-a130-70f7bae4ff98', name: 'Акушерство' },
  { value: 'e9b95a9a-c298-461a-a5bf-4840211ffb9f', name: 'Анестезиология' },
  { value: 'b9328eef-6e60-4e45-9cea-a4c77b0d78d8', name: 'Гипертология' },
  { value: '21aae270-bddb-4bd8-82b8-8092774f8b4f', name: 'Груминг' },
  { value: 'fb96c73f-36b4-4e1d-9bc4-e1bdf74fcfe7', name: 'Дерматология' },
  { value: 'd79132e8-d6cf-4c4d-8fdc-ff9105b1e3d4', name: 'Другое' },
  { value: '615fee6c-046a-4e75-b6b2-ed11d177118a', name: 'Кардиология' },
  {
    value: '05f4589b-aa71-4f77-84d5-4ca6dee55ca9',
    name: 'Лабораторная диагностика',
  },
  { value: '6b8d295a-52f1-4c6b-bb92-0f87ed435fcf', name: 'Неврология' },
  { value: 'd9406e65-210b-4d0a-9ef7-9f131db2fa5b', name: 'Онкология' },
  { value: '88d456c3-fdcd-4c91-846e-e0f41fd60bf3', name: 'Офтальмология' },
  { value: 'a4b12361-ec30-4410-92eb-3a7c10303b56', name: 'Стоматология' },
  { value: 'd5a8fafb-08d7-4298-90ea-79e1a70bea83', name: 'Терапия' },
  { value: '2c09a9e1-ef21-44ef-a961-587fe8fd8bc2', name: 'Хирургия' },
  {
    value: 'a078c76d-f0f3-4931-bbcc-d70b76112163',
    name: 'Экзотические животные',
  },
  { value: '5ca8f2c5-ab68-450c-b336-0a4f068fbf7a', name: 'Эндокринология' },
  { value: '49b5d8da-b575-42d9-8bef-32f367d33af9', name: 'Эндоскопия' },
];

export const specializations: SelectInterface[] = [
  { value: '2b1ee8a9-ec75-4948-9049-55ebe68f7e97', name: 'Анестезиолог' },
  { value: 'a24704c7-cc5b-4b88-ae47-24e83a72789e', name: 'Герпетолог' },
  { value: 'e587abcb-13d9-4468-8e6b-173f7adec54d', name: 'Гинеколог-акушер' },
  { value: '8de65567-19fd-4c3a-8c57-d9580bf12e27', name: 'Грумер' },
  { value: '46dbd96b-11dd-40d6-8105-c80d74723605', name: 'Дерматолог' },
  { value: 'bb4185ac-7360-447e-9ced-10cb4ccc1184', name: 'Кардиолог' },
  { value: '5fb8978f-ec08-41f7-8e4a-93b23fb9082a', name: 'Лаборант' },
  { value: '16e5bd5d-d6bf-443e-96ba-7f30f89caf92', name: 'Невролог' },
  { value: 'f4d36a52-92de-4003-a48b-1d8c924cd97f', name: 'Онколог' },
  { value: '2d85c5d9-12e5-410a-9b3c-7b69f75b0ae8', name: 'Офтальмолог' },
  {
    value: '30ff1029-1373-4099-929f-35d80566e1e2',
    name: 'Специалист по грызунам и экзотическим животным',
  },
  { value: '98e9ec43-4045-4229-bb4f-950c30ee1e80', name: 'Стоматолог' },
  { value: '005c4a19-2be0-4a8c-8ac0-d6ce79ea4261', name: 'Терапевт' },
  { value: '331e307b-ae36-4a1b-b020-fcb5dd246008', name: 'Хирург' },
  { value: '113756c1-9f79-40b1-8b38-aaf133d82529', name: 'Эндокринолог' },
  { value: '5725ef01-8772-414a-8207-7e1a1fc984a9', name: 'Эндоскопист' },
];

export const typeAppointment: SelectInterface[] = [
  { value: '71e0f5b1-f8c4-4ee8-acb7-7abb11e20ed7', name: 'В клинике' },
  { value: 'fdfac7a2-a126-4869-be65-a17ec8b71925', name: 'На выезд' },
];

export const weekDay: SelectInterface[] = [
  { value: 'a752dcfb-c499-4462-b96c-e791b6696764', name: 'Понедельник' },
  { value: 'c3e40532-f401-465e-b907-59dd14532635', name: 'Вторник' },
  { value: '34367590-94ee-47c6-9c0c-4e7fceae5164', name: 'Среда' },
  { value: '9f47e8b6-aabd-4582-bae7-582415a4e8c3', name: 'Четверг' },
  { value: '7ad02a66-8b15-4250-8a84-a71200667317', name: 'Пятница' },
  { value: 'b4749969-9871-452a-8257-52283e058555', name: 'Суббота' },
  { value: 'a1053a1e-cad6-4207-b526-d29d83183214', name: 'Воскресенье' },
];

export const typeId: SelectInterface[] = [
  { value: 'bdceb600-41f3-4f14-97e3-86f593304a8b', name: 'Хирургия' },
  {
    value: '094196de-6542-402b-aad8-faa6ff2ac5c8',
    name: 'Манипуляции косметические',
  },
  {
    value: '5f188ffb-89b2-4e34-b29a-a6bf0dd30a36',
    name: 'Манипуляции лабораторные',
  },
  { value: '7a617d96-77c8-471a-964a-9ee136758417', name: 'Инъекции' },
  { value: '2e1572a8-5e7f-4e9a-9036-0f3f350021a3', name: 'Вакцинация' },
  { value: '3c6e773a-1907-454a-950e-114172c7596d', name: 'Зоогостиница' },
  { value: 'aa883cc5-3a75-4e4d-981e-36d21148fc94', name: 'Стационар' },
  { value: 'ee108545-09ee-4d8f-bddd-54d786a8955e', name: 'Первичный прием' },
  { value: 'c935fd5f-b78a-4e13-a6ce-4970e462e034', name: 'Вторичный прием' },
];

export const consultationType: SelectInterface[] = [
  { value: 'B9BA8894-E27B-4E87-A39B-30C5482E4320', name: 'Чат' },
  { value: '0AC8BDBD-FE59-494C-AA8C-A2B2744A5064', name: 'Видео' },
  { value: '64B5F3DC-D763-4C64-965B-C8BE6F89E8BA', name: 'В клинике' },
];

export const frequency: SelectInterface[] = [
  { value: 'f256a5ac-12d1-49e5-b16a-ea590d252098', name: 'В день' },
  { value: '384d0816-3761-4a49-9aad-67e4c50f3456', name: 'В неделю' },
  { value: '20eb3296-4744-413d-94d2-2486c0ea688c', name: 'В месяц' },
  { value: '92af06ff-1ad3-45e0-a268-679c3bb4f056', name: 'В год' },
];

export const unitOfMeasurement: SelectInterface[] = [
  { value: 'b31e6c3c-f2c2-4915-8c34-bf6598705480', name: 'гр' },
  { value: 'fc9be477-cc9f-4da5-9cce-6ceee675b30f', name: 'доза' },
  { value: 'e8d1d08f-5112-4562-982c-aaacfcf56a8e', name: 'единица' },
  { value: 'c9c374cd-c770-4e1e-9a71-6d909262af40', name: 'кг' },
  { value: '59b79e55-5338-45e3-a37f-e9f7e4fa79a7', name: 'литр' },
  { value: '18661795-b37a-4278-8d9b-87cff183dc43', name: 'метр' },
  { value: 'ca1d69ec-23a2-4ddf-8a0a-d2e7cfa3bcc7', name: 'мл' },
  { value: '1a480c2f-cda1-4bfd-a64f-3ee94e579fc5', name: 'упаковка' },
  { value: '78455301-d450-4b18-9e14-a6be9bfa8035', name: 'флакон' },
  { value: '673e38ac-ef01-479f-970b-16d51abff3ee', name: 'шт' },
];

export const medicineType: SelectInterface[] = [
  { value: 'afa5099b-ca04-429f-a867-3a29f5474ec0', name: 'Вакцина' },
  { value: 'a62f0aab-e18d-4007-a36f-38d94b417797', name: 'Инъекция' },
  { value: '66d9166a-975a-46d8-9de6-89b1be26b7bc', name: 'Другое' },
];

export const vaccinationStatus: SelectInterface[] = [
  { value: 'AB25D50A-F68A-46A2-AD0E-26E72154A0A3', name: 'Запланирована' },
  { value: '83BD15F9-BC68-40F0-BFB5-6FBACC9AD196', name: 'Выполнена' },
  { value: 'ED8A09B2-DE29-4CAB-9008-072D219E1186', name: 'Не выполнена' },
];

export const vaccinationCategory: SelectInterface[] = [
  { value: 'F211EC6A-6C84-4FF9-9E25-A282C5FF3154', name: 'Первичная' },
  { value: '36540F95-E87D-4A00-BC4A-38AA631263EB', name: 'Вторичная' },
];
