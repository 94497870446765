import { Component, Input, OnInit } from '@angular/core';
import { AppointmentsGridInterface } from '../../../interfaces/appointments/appointments-grid.interface';
import { AppointmentAddTimegridDialogComponent } from '../appointment-add-timegrid-dialog/appointment-add-timegrid-dialog.component';
import { MatDialog } from '@angular/material';
import { AppointmentAdminTimegridDialogComponent } from '../../../../clinic/schedule/appointment-edit/appointment-admin-timegrid-dialog/appointment-admin-timegrid-dialog.component';

@Component({
  selector: 'app-appointment-grid',
  templateUrl: './appointment-grid.component.html',
  styleUrls: ['./appointment-grid.component.scss'],
})
export class AppointmentGridComponent implements OnInit {
  @Input() appointmentsGrid: AppointmentsGridInterface;
  @Input() dateRange: [];
  @Input() admin: boolean;

  objectKeys = Object.keys;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  showTimeGrid(item, date, key) {
    const dialogRef = this.dialog.open(AppointmentAddTimegridDialogComponent, {
      data: {
        item: item,
        date: date,
        key: key,
      },
      panelClass: 'time-grid-clinic-dialog',
    });

    dialogRef.afterClosed().subscribe((response) => {
      this.appointmentsGrid[response.key].WorkDates[response.date].Length = 0;

      for (let item of this.appointmentsGrid[response.key].WorkDates[
        response.date
      ].Appointments) {
        if (item.IsEnable) {
          this.appointmentsGrid[response.key].WorkDates[response.date].Length++;
        }
      }
    });
  }

  showAdminTimeGrid(item, date, key) {
    const dialogRef = this.dialog.open(
      AppointmentAdminTimegridDialogComponent,
      {
        data: {
          item: item,
          date: date,
          key: key,
        },
        panelClass: 'time-grid-clinic-dialog',
      }
    );

    dialogRef.afterClosed().subscribe((response) => {});
  }
}
