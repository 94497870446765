import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { ScheduleComponent } from './schedule.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatRippleModule,
  MatSelectModule,
} from '@angular/material';
import { ScheduleRoutingModule } from './schedule-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { ImageCropperModule } from '../../shared/plugins/cropper';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ScheduleAddComponent } from './schedule-add/schedule-add.component';
import { ScheduleEditComponent } from './schedule-edit/schedule-edit.component';
import { AppointmentAddComponent } from './appointment-add/appointment-add.component';
import localeRu from '@angular/common/locales/ru';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TextMaskModule } from 'angular2-text-mask';
import { CatalogNamePipe } from '../../shared/pipes/catalog-name.pipe';
import { AppointmentEditComponent } from './appointment-edit/appointment-edit.component';
import { AppointmentAdminTimegridDialogComponent } from './appointment-edit/appointment-admin-timegrid-dialog/appointment-admin-timegrid-dialog.component';
import { AppointmentEditDialogComponent } from './appointment-edit/appointment-edit-dialog/appointment-edit-dialog.component';

registerLocaleData(localeRu);

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    ScheduleRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    ImageCropperModule,
    PipesModule,
    TextMaskModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    MatIconModule,
    MatRippleModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
  ],
  declarations: [
    ScheduleComponent,
    ScheduleAddComponent,
    ScheduleEditComponent,
    AppointmentAddComponent,
    AppointmentEditComponent,
    AppointmentAdminTimegridDialogComponent,
    AppointmentEditDialogComponent,
  ],
  entryComponents: [
    AppointmentAdminTimegridDialogComponent,
    AppointmentEditDialogComponent,
  ],
  providers: [
    DatePipe,
    CatalogNamePipe,
    MatDatepickerModule,
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ScheduleModule {}
