import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../../validators/custom.validators';
import { SelectInterface } from '../../../interfaces/select.interface';
import { petTypes } from '../../../catalogs/pets.catalog';
import { TextMaskConfig } from 'angular2-text-mask';
import { PhoneMask } from '../../../masks/phone.mask';
import { AppointmentsTimeGridInterface } from '../../../interfaces/appointments/appointments-time-grid.interface';
import { AppointmentRegisterUserDataInterface } from '../../../interfaces/appointments/appointment-register-user-data.interface';
import { api } from '../../../../../environments/api';
import { AppointmentPetInterface } from '../../../interfaces/appointments/appointment-pet.interface';
import { concatMap, tap, timeout } from 'rxjs/operators';
import { SimpleRegisterResponseInterface } from '../../../interfaces/auth/simple-register-response.interface';
import { UserIdAndSecretInterface } from '../../../interfaces/user/user-id-and-secret.interface';
import { AppointmentRegisterPetDataInterface } from '../../../interfaces/appointments/appointment-register-pet-data.interface';
import { AppointmentRegisterPetResponseInterface } from '../../../interfaces/appointments/appointment-register-pet-response.interface';
import { AppointmentAddUserDataInterface } from '../../../interfaces/appointments/appointment-add-user-data.interface';
import { AppointmentAddDataInterface } from '../../../interfaces/appointments/appointment-add-data.interface';
import { AppointmentAddResponseInterface } from '../../../interfaces/appointments/appointment-add-response.interface';
import { Subscription } from 'rxjs/Subscription';
import { ClinicService } from '../../../services/clinic.service';
import { DialogService } from '../../../services/dialog.service';
import { typeAppointment } from '../../../catalogs/clinic.catalogs';

@Component({
  selector: 'app-create-appointment-new-user',
  templateUrl: './create-appointment-new-user.component.html',
  styleUrls: ['./create-appointment-new-user.component.scss'],
})
export class CreateAppointmentNewUserComponent implements OnInit {
  @Input() appointment: AppointmentsTimeGridInterface;
  @Input() selectServices: SelectInterface[];

  @Output() preloader = new EventEmitter<boolean>();
  @Output() setSuccess = new EventEmitter<boolean>();

  hideForm: boolean = false;
  form: FormGroup;

  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;

  selectPetTypes: SelectInterface[] = petTypes;

  signUpSubscribe: Subscription;
  createAppointmentSubscribe: Subscription;

  needAddress = false;

  phoneMask: TextMaskConfig = {
    mask: PhoneMask,
  };

  constructor(
    private customValidators: CustomValidators,
    private clinicService: ClinicService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    if (this.appointment.PlaceAppointmentId == typeAppointment[1].value)
      this.needAddress = true;
    this.formInit();
  }

  formInit() {
    this.form = new FormGroup({
      firstName: new FormControl(null, [Validators.required]),
      surName: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [
        Validators.required,
        this.customValidators.email,
      ]),
      phone: new FormControl(null, [
        Validators.required,
        this.customValidators.phone,
      ]),
      petType: new FormControl(null, [
        Validators.required,
        this.customValidators.autofillForceRequire,
      ]),
      petName: new FormControl(null, [Validators.required]),
      complaint: new FormControl(null, [Validators.required]),
      service: new FormControl(null, [
        Validators.required,
        this.customValidators.autofillForceRequire,
      ]),
    });

    if (this.needAddress) {
      this.form.addControl(
        'address',
        new FormControl(null, [Validators.required])
      );
    }
  }

  submit() {
    const value = this.form.value;
    const clinicData = this.clinicService.getClinicFullData();

    this.preloader.emit(true);
    this.hideForm = true;

    const phone = value.phone.replace(/\D+/g, '');

    const requestUser: AppointmentRegisterUserDataInterface = {
      Firstname: value.firstName,
      Surname: value.surName,
      Email: value.email,
      Phone: phone,
      TypeId: api.typesOfUsers.owner,
    };

    const pet: AppointmentPetInterface = {
      Name: value.petName,
      Type: value.petType.value,
    };

    const typeId = clinicData.AccClinicService.find(
      (item) => item.Id === value.service.value
    ).TypeId;

    !api.production ? console.log('fast-test', pet) : null;

    this.signUpSubscribe = this.clinicService
      .registerUser(requestUser)
      .pipe(
        timeout(api.requestTimeout),
        tap(
          (response: SimpleRegisterResponseInterface) => {
            !api.production
              ? console.log('SimpleRegisterResponseInterface', response)
              : null;

            if (!response.SimpleRegistrationResult.Success) {
              this.signUpSubscribe.unsubscribe();

              let title = this.errorTitle;
              let error = this.errorMessage;

              const warnings = response.SimpleRegistrationResult.Warnings;

              for (let key in warnings) {
                if (warnings[key].Code == 503) {
                  title = 'Пользователь уже существует!';
                  error =
                    'Пользователь с таким E-mail уже существует. Пожалуйста укажите другой E-mail.';
                }
              }

              this.dialogService.openStatusDialog(title, error, true);

              this.preloader.emit(false);
              this.hideForm = false;
              this.signUpSubscribe.unsubscribe();
            }
          },
          () => {
            this.preloader.emit(false);
            this.hideForm = false;
            this.dialogService.showStandardError();
            this.signUpSubscribe.unsubscribe();
          }
        ),
        concatMap((response: SimpleRegisterResponseInterface) => {
          const userData: UserIdAndSecretInterface = response
            .SimpleRegistrationResult.UserData as UserIdAndSecretInterface;

          const requestPet: AppointmentRegisterPetDataInterface = {
            UserId: userData.UserId,
            SecretKey: userData.SecretKey,
            Pet: {
              Name: pet.Name,
              Type: pet.Type,
            },
          };

          return this.clinicService
            .registerPet(requestPet)
            .pipe(timeout(api.requestTimeout));
        })
      )
      .subscribe(
        (response: AppointmentRegisterPetResponseInterface) => {
          !api.production
            ? console.log('AppointmentRegisterPetResponsiveInterface', response)
            : null;

          if (response.RegistrationPetResult.Success) {
            const responseUserData: AppointmentRegisterPetDataInterface = response
              .RegistrationPetResult
              .UserData as AppointmentRegisterPetDataInterface;

            const userData: AppointmentAddUserDataInterface = {
              UserId: responseUserData.UserId,
              PetId: responseUserData.Pet.Id,
              SpecialistId: this.appointment.SpecialistId,
              AccountId: this.appointment.AccountId,
            };

            let address =
              clinicData.AccountData.City +
              ', ' +
              clinicData.AccountData.Address;

            if (this.needAddress) {
              address = value.address;
            }

            const appointmentData: AppointmentAddDataInterface = {
              StartDate: this.appointment.StartDate,
              DueDate: this.appointment.DueDate,
              Compliant: value.complaint,
              ServiceId: value.service.value,
              Name: requestUser.Firstname + ' ' + requestUser.Surname,
              Phone: requestUser.Phone,
              Email: requestUser.Email,
              PetName: pet.Name,
              Price: 0,
              TypeId: typeId,
              PlaceAppointmentId: this.appointment.PlaceAppointmentId,
              Address: address,
            };

            this.createAppointmentSubscribe = this.clinicService
              .createAppointment(userData, appointmentData)
              .pipe(timeout(api.requestTimeout))
              .subscribe(
                (response: AppointmentAddResponseInterface) => {
                  !api.production
                    ? console.log('AppointmentAddResponseInterface', response)
                    : null;

                  this.preloader.emit(false);
                  this.hideForm = false;

                  if (response.CreateAppointmentResult.Success) {
                    this.dialogService.openStatusDialog(
                      'Запись на прием прошла успешно!',
                      'Пользователь был успешно записан на прием в клинику.'
                    );
                    this.setSuccess.emit();
                  } else {
                    this.dialogService.openStatusDialog(
                      'Не удалось завершить запись на прием!',
                      'Пользователь был успешно создан, но возникла проблема при записи на прием. Попробуйте повторить попытку, при этом вы можете воспользоваться поиском пользователя т.к. он уже создан.',
                      true
                    );
                  }

                  this.createAppointmentSubscribe.unsubscribe();
                },
                () => {
                  this.preloader.emit(false);
                  this.hideForm = false;
                  this.dialogService.openStatusDialog(
                    'Не удалось завершить запись на прием!',
                    'Пользователь был успешно создан, но возникла проблема при записи на прием. Попробуйте повторить попытку, при этом вы можете воспользоваться поиском пользователя т.к. он уже создан.',
                    true
                  );
                  this.createAppointmentSubscribe.unsubscribe();
                }
              );

            this.signUpSubscribe.unsubscribe();
          } else {
            this.preloader.emit(false);
            this.hideForm = false;
            this.dialogService.showStandardError();
            this.signUpSubscribe.unsubscribe();
          }
        },
        () => {
          this.preloader.emit(false);
          this.hideForm = false;
          this.dialogService.showStandardError();
          this.signUpSubscribe.unsubscribe();
        }
      );
  }

  test() {
    console.log(this.form);

    const pet: AppointmentPetInterface = {
      Name: this.form.value.petName,
      Type: this.form.value.petType.value,
    };

    console.log(pet);
  }
}
