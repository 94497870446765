import { Component, OnInit } from '@angular/core';
import { ClinicService } from '../../../shared/services/clinic.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { api } from '../../../../environments/api';
import { GetClinicSpecialistsResponseInterface } from '../../../shared/interfaces/clinic/get-clinic-specialists-response.interface';
import { ClinicListSpecialistsInterface } from '../../../shared/interfaces/clinic/clinic-list-specialists.interface';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import { doctorSpecialization } from '../../../shared/catalogs/doctor.catalog';
import { SpecialistAddDialogComponent } from '../specialist-add-dialog/specialist-add-dialog.component';
import { DialogService } from '../../../shared/services/dialog.service';
import { PreSwitchRelationTypeInterface } from '../../../shared/interfaces/user/pre-switch-relation-type.interface';
import { UserService } from '../../../shared/services/user.service';
import { SwitchRelationTypeResponseInterface } from '../../../shared/interfaces/user/switch-relation-type-response.interface';

@Component({
  selector: 'app-specialists-list',
  templateUrl: './specialists-list.component.html',
  styleUrls: ['./specialists-list.component.scss'],
})
export class SpecialistsListComponent implements OnInit {
  preloader: boolean = true;

  getClinicSpecialistSubscribe: Subscription;
  removeSpecialistSubscription: Subscription;

  timeout = true;

  specialistsList: ClinicListSpecialistsInterface[] = [];

  doctorSpecializationCatalog: SelectInterface[] = doctorSpecialization;

  constructor(
    public clinicService: ClinicService,
    private dialog: MatDialog,
    private router: Router,
    private dialogService: DialogService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getClinicSpecialist();
  }

  /*
   * Получаем список специалистов работающих в клинике
   * */

  getClinicSpecialist() {
    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.getClinicSpecialistSubscribe.unsubscribe();
    }, api.requestTimeout);

    const request = this.clinicService.getClinicFullData().AccountData
      .AccountId;

    this.getClinicSpecialistSubscribe = this.clinicService
      .getClinicSpecialists(request)
      .subscribe(
        (response: GetClinicSpecialistsResponseInterface) => {
          !api.production ? console.log('GetSpecialistResult', response) : null;

          clearTimeout(timeout);

          if (response.GetSpecialistResult.Success) {
            this.specialistsList = response.GetSpecialistResult.Specialists;
            this.preloader = false;
          } else {
            this.dialogService.showStandardError();
          }
        },
        () => {
          clearTimeout(timeout);
          this.dialogService.showStandardError();
        }
      );
  }

  addSpecialist() {
    const dialogRef = this.dialog.open(SpecialistAddDialogComponent, {
      panelClass: 'specialist-add-dialog',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success && result.invite) {
        this.dialogService.openStatusDialog(
          'Вы пригласили специалиста!',
          'Вы успешно пригласили специалиста в клинику.'
        );
      } else if (result && result.success) {
        this.dialogService.openStatusDialog(
          'Вы создали и пригласили специалиста!',
          'Вы успешно создали и пригласили нового специалиста в клинику.'
        );
      }
    });
  }

  /*
   * Подтверждение на Увольнение отклоненой заявки
   * */

  checkBeforeRemove(id, index) {
    this.dialogService
      .openSubmitDialog(
        'Подтвердите действие!',
        'Вы действительно хотите уволить специалиста? После этого он будет полностью отвязан от вашей клиники.'
      )
      .subscribe((response) => {
        response ? this.removeSpecialist(id, index) : null;
      });
  }

  removeSpecialist(userId, index) {
    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.removeSpecialistSubscription.unsubscribe();
    }, api.requestTimeout);

    const request: PreSwitchRelationTypeInterface = {
      AccountId: this.clinicService.getClinicFullData().AccountData.AccountId,
      UserId: userId,
      LinkTypeId: api.switchRelationTypeList.remove,
    };

    this.removeSpecialistSubscription = this.userService
      .switchRelationType(request)
      .subscribe(
        (response: SwitchRelationTypeResponseInterface) => {
          !api.production
            ? console.log('SwitchRelationTypeResponseInterface', response)
            : null;
          clearTimeout(timeout);

          if (!response.SwitchRelationTypeResult.Success) {
            this.dialogService.showStandardError();
          } else {
            this.dialogService.openStatusDialog(
              'Специалист уволен!',
              'Вы успешно уволили специалиста из клиники.'
            );
            this.specialistsList.splice(index, 1);
          }
        },
        () => {
          clearTimeout(timeout);
          this.dialogService.showStandardError();
        }
      );
  }
}
