import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectInterface } from '../../shared/interfaces/select.interface';
import {
  medicineType,
  unitOfMeasurement,
} from '../../shared/catalogs/clinic.catalogs';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  Medicines,
  MedicinesForm,
} from '../../shared/interfaces/clinic/medicines';
import { Observable } from 'rxjs/Observable';
import { map, startWith, timeout } from 'rxjs/operators';
import { ClinicService } from '../../shared/services/clinic.service';
import { api } from '../../../environments/api';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { DialogService } from '../../shared/services/dialog.service';
import { ClinicEditResponseInterface } from '../../shared/interfaces/clinic/clinic-edit-response.interface';

@Component({
  selector: 'app-clinic-medicines',
  templateUrl: './clinic-medicines.component.html',
  styleUrls: ['./clinic-medicines.component.scss'],
})
export class ClinicMedicinesComponent implements OnInit, OnDestroy {
  selectUnitOfMeasurement: SelectInterface[] = unitOfMeasurement;
  selectMedicineType: SelectInterface[] = medicineType;
  form: FormGroup;
  medicinesList: Medicines[] = [];
  filteredMedicines$: Observable<Medicines[]>;

  constructor(
    private fb: FormBuilder,
    private clinicService: ClinicService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    const localMedicines = this.clinicService.getClinicFullData().AccountData
      .ClinicMedicines;
    if (localMedicines && localMedicines.length > 0) {
      localMedicines.map((x) => {
        this.medicinesList.push({
          name: x.Name,
          dose: x.UnitOfMeasurementId,
          type: x.MedicineTypeId,
        });
      });
    }
    this.form = this.fb.group({
      search: new FormControl(''),
      medicines: this.fb.array(
        this.medicinesList.map((x) =>
          this.fb.group({
            name: new FormControl(x.name, {
              validators: [Validators.required],
              updateOn: 'blur',
            }),
            dose: new FormControl(x.dose, {
              validators: [Validators.required],
              updateOn: 'blur',
            }),
            type: new FormControl(x.type, {
              validators: [Validators.required],
              updateOn: 'blur',
            }),
          })
        )
      ),
    });

    this.filteredMedicines$ = this.form.valueChanges.pipe(
      map((value) => {
        if (value.search === '') {
          return value.medicines.map((x, index) => ({ ...x, index }));
        }
        const queryLower = value.search.toLowerCase();
        return value.medicines
          .filter((x) => {
            return x.name.toLowerCase().includes(queryLower);
          })
          .map((x) => ({ ...x, index: value.medicines.indexOf(x) }));
      }),
      startWith(
        this.medicines.value.map((x) => ({
          ...x,
          index: this.medicines.value.indexOf(x),
        }))
      )
    );
  }

  addMedicine() {
    this.medicines.push(this.createMedicine());
  }

  get medicines(): FormArray {
    return this.form.get('medicines') as FormArray;
  }

  removeMedicine(index) {
    const control = this.form.controls.medicines as FormArray;
    control.removeAt(index);
  }

  getMedicineFormGroup(index: number): FormGroup {
    return this.medicines.at(index) as FormGroup;
  }

  createMedicine(): FormGroup {
    return this.fb.group({
      name: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),
      dose: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),
      type: new FormControl(null, {
        validators: [Validators.required],
        updateOn: 'blur',
      }),
    });
  }

  prepareForm() {
    const medicines: MedicinesForm[] = [];
    this.form.value.medicines.map((x) => {
      medicines.push({
        Name: x.name,
        MedicineTypeId: x.type,
        UnitOfMeasurementId: x.dose,
      });
    });
    return medicines;
  }

  onSubmit() {
    const medicines = this.prepareForm();

    this.clinicService
      .editMedicines(medicines)
      .pipe(timeout(api.requestTimeout), untilDestroyed(this))
      .subscribe(
        (response: ClinicEditResponseInterface) => {
          !api.production ? console.log('EditAccountResult', response) : null;

          if (response.EditAccountResult.Success) {
            this.dialogService.openStatusDialog(
              'Информация успешно изменена!',
              'Вы успешно сохранили информацию о лкарствах клиники.',
              false,
              'back-info'
            );
            this.clinicService.clinicFullData.AccountData.ClinicMedicines = medicines;
          } else {
            this.dialogService.showStandardError();
          }
        },
        () => {
          this.dialogService.showStandardError();
        }
      );
  }

  ngOnDestroy(): void {}
}
