import { SelectInterface } from '../interfaces/select.interface';

export const petTypes: SelectInterface[] = [
  { value: '6e40453e-010e-452e-b7ab-e0bb8a9fe12b', name: 'Кошка' },
  { value: '5e802ebd-39bb-4a50-9126-a7d35ff756f9', name: 'Собака' },
  { value: 'bba3b12e-d1be-4cec-a447-3496a46bcf88', name: 'Лошадь' },
  { value: 'b26a96a4-e31d-43da-86a9-c76794b5dfa8', name: 'КРС' },
  { value: '495a6318-5f9c-47f2-9f76-5de3ca7640e5', name: 'МРС' },
  { value: 'aebbe327-8414-46a4-af65-ddf8c3c1e1db', name: 'Грызун' },
  { value: '51263b54-d9f2-4d15-a78e-51da18cc21ff', name: 'Кролик' },
  { value: 'b5a662f4-4788-416c-9006-fed157e8ef5a', name: 'Дикое животное' },
  { value: 'a0a2559c-4d28-4382-9b07-e67c2eebb495', name: 'Свинья' },
  { value: '848f28b4-d608-48c8-adb4-417cc0b5ce11', name: 'Рептилия' },
  { value: 'e25bcb51-9dc0-41a6-a399-5a24d1c27b01', name: 'Амфибия' },
  { value: 'c60ad343-07d7-49c7-adf9-20a2c06e8588', name: 'Птица' },
  { value: 'cfddd772-b6d6-431c-9bd2-9e37bb8abd9d', name: 'Рыба' },
];

export const petGender: SelectInterface[] = [
  { value: 'Male', name: 'Мужской' },
  { value: 'Female', name: 'Женский' },
];

export const petAges: SelectInterface[] = [
  { value: '56d352a4-db4b-4886-9c51-cf2e611dcfd5', name: '0-2 мес' },
  { value: '00f208e7-0c01-4e33-af85-db8d40abd155', name: '2-5 мес' },
  { value: '9994b689-3714-4c06-bccc-548d1b74e259', name: '6 мес - 3 года' },
  { value: '64d887b5-126b-4ae9-8d4a-55d529abb132', name: '3-8 лет' },
  { value: '345dfdc5-d387-40f6-8f03-57f2265724c4', name: 'старше 8 лет' },
  { value: 'd42d966b-4866-4e33-bc6d-5b9cf8e97b07', name: '6 мес - 1,5 года' },
  { value: 'ffa37052-06d1-4476-bfcb-c2aa2bc2b1df', name: '1,5 года - 5 лет' },
  { value: '73cd08bd-9bd0-4b39-9d77-7a20d728b2a3', name: 'старше 5 лет' },
];

export const petSizes: SelectInterface[] = [
  { value: '3a71845c-b04d-4091-9f96-084f602a65b1', name: 'Мини' },
  { value: '09fde6e6-0774-486e-84f0-9b6ff717caa9', name: 'Маленький' },
  { value: '9eb683a6-dac5-4b8f-aaa8-1c7df9cc8fb2', name: 'Средний' },
  { value: 'b79ec9f7-f3dc-4174-a937-30daa5b8e22f', name: 'Крупный' },
];

export const petLengthCoat: SelectInterface[] = [
  { value: '704818d7-78f9-4620-9d48-6f2ec7a2bce5', name: 'Короткая' },
  { value: '810c9afd-3811-43ef-9108-1128f941ca4c', name: 'Без шерсти' },
  { value: 'b9b5c7a0-d1af-42df-8333-603dca04110c', name: 'Средняя' },
  { value: '4fe41dc4-2309-453a-af10-2437af4e5aca', name: 'Длинная' },
];

export const petPlaceKeep: SelectInterface[] = [
  { value: '241209e4-6e98-423b-ae5a-3d7338a1926e', name: 'За городом' },
  { value: '99b2a322-c413-4ed4-8110-1c0132be78b2', name: 'В городе' },
];

export const petConditionKeep: SelectInterface[] = [
  { value: '4f7816b1-db9d-4fdd-b589-47b0687be9dc', name: 'В помещении' },
  { value: 'ba645397-d74e-4861-8686-450b1cb1e394', name: 'На улице' },
];

export const petProvenance: SelectInterface[] = [
  { value: '76667d78-27a5-4a0c-80e7-df769a1bb9d0', name: 'Подобрал на улице' },
  { value: '0250ae24-d3fe-440b-a4e7-772f45c45a6e', name: 'Подобрал на улице' },
  { value: '159eaacd-27a3-48b2-9556-9c0a5087beb4', name: 'Взял у знакомых' },
  { value: '75792ce4-fbed-4f8e-bca0-0cc62736df42', name: 'Взял из приюта' },
  { value: '79fb75bf-e8bb-4db7-a4b8-d79dfcfdbbdf', name: 'Купил у заводчика' },
  { value: '04660e1d-0b9d-4f4c-bd86-5f3d0aaa99ff', name: 'Купил в магазине' },
];

export const petIsNeutered: SelectInterface[] = [
  { value: false, name: 'Не кастрирован' },
  { value: true, name: 'Кастрирован' },
];

export const petVaccinationType: SelectInterface[] = [
  { value: '6118841B-C20B-4460-9F0F-10E9E644D356', name: 'Rabies' },
  { value: '379AEEA2-BE62-4DDE-9A07-62AF1A521A7C', name: 'RL' },
  { value: 'E3131FED-C8E4-493A-88E4-8B576B54B97F', name: 'Lepto' },
  { value: 'C63F317A-2D5A-4B5C-9F4E-E49CB76A5F02', name: 'DHP' },
  { value: 'B75F72E7-FA08-4BEA-9C07-D74A867FEC44', name: 'DHPPI' },
  { value: '9B423D76-F292-49FF-B889-01AE91B61149', name: 'KC' },
  { value: '947CB7D2-6264-4E86-942D-D5727C28F8CB', name: 'PIRO' },
  { value: '28909544-6F67-4130-BE1A-4A28B24D65E8', name: 'PUPPY DP' },
  { value: '10E482BE-A11B-47F8-A052-2187777CD593', name: 'TRICAT TRIO' },
  { value: 'C107379E-F9FC-40EE-B551-2BD8A4DEB297', name: 'RCP' },
  { value: '21F99734-306B-4921-9D2E-64CC824DCE7D', name: 'RCPCh' },
  { value: '32516E80-E5C6-4942-9C24-BC68080E636B', name: 'V' },
  { value: 'D26081AC-B4F0-454E-A5F4-07A647A920D8', name: 'В' },
  { value: 'F27F4122-834C-48D9-99AE-5C23B6E57117', name: 'Рабиген МОНО' },
  { value: 'BE006230-2A83-4A97-86C3-49A2165B2F3D', name: 'Прквенза Те' },
  { value: '798AF3F2-FB93-4D34-999D-8E60106E9A6E', name: 'DHPPI2 - L' },
  { value: '99D2D4B3-D459-48D2-8851-615F73A5160A', name: 'PIRO' },
  { value: '392B4DAE-2D76-4EC7-B216-CBD3E5614C94', name: 'DHPPI2 - LR' },
  { value: '28AE5796-CC31-42CE-BB76-3F9DEA5687F5', name: 'FeLV' },
  { value: '44727D09-2863-4DC4-8876-17434D0CDEF4', name: 'Вангард 5/L' },
  { value: '04E365B9-2D05-47A5-85E5-5F090CE6AE67', name: 'Вангард Плюс 5 L4' },
  { value: 'B30FC56E-BB30-4772-9F9D-B33CE9EC8C30', name: 'Дефенсор 3' },
  { value: 'FBE6AB8A-3B59-41C6-8E3E-7851967E8DEE', name: '8' },
  { value: 'DCD64A12-FB29-4350-B145-37F7A16A45DE', name: '4' },
  { value: '69076753-516B-4BE4-91D8-5FC5335D0E0D', name: 'F' },
  {
    value: 'EAD3D6A8-ED61-4FF6-B62D-A816F935F92E',
    name: 'Вангард Плюс 5 L4 CV',
  },
  { value: '24892BEB-04A1-4345-A728-1F393FAF045E', name: '4' },
  { value: 'EF7AF36E-7A7F-4666-B5CA-DE399D5B2112', name: '6' },
  { value: '043B7264-8DE6-45BE-A389-1C0F71B0A206', name: '7' },
  {
    value: '96B439C2-29B8-4B2D-B778-4F1D284DC31E',
    name: 'Каниген DHA2PPI / L',
  },
  { value: '0DE6AEEE-1683-4715-9C34-9E5B8EEA6B40', name: 'Фелиген CRP' },
  { value: 'BFADED4B-E799-4480-AB6E-B6A8CB52CFA7', name: 'М + ВГБК' },
  { value: 'EE361B65-D2DC-4341-BD9B-77DBFA13AF6B', name: 'ДИПЕНТАВАК' },
  { value: 'C440E490-F9F5-43BB-BCB9-2F40D8049A3E', name: 'Вангард 7' },
  { value: '38C6B875-E7D2-4CAA-931C-CE55652C8F58', name: 'Лейкофелиген' },
  {
    value: '43A3ABAE-E42F-4A67-957A-589C79CA2E05',
    name: 'Каниген DHA2PPI / LR',
  },
  { value: '3571A879-D2FD-438C-9AE9-6F7CA04789F5', name: 'Фелиген CRP/R' },
  { value: '2BBBFC7C-3187-46EC-BAC5-55C42250E052', name: 'Биовак DPAL' },
];

export const petBreedCat: SelectInterface[] = [
  { value: '6b172a1f-24ca-42b7-8a73-28bf5d863fc2', name: 'Абиссинская кошка' },
  { value: 'd6cb26d0-b0e6-4f7a-ae9d-16d5dc0aed72', name: 'Австралийский мист' },
  { value: '1a0a0f96-013d-4965-bc11-b743b356290b', name: 'Азиатская табби' },
  {
    value: 'f75c4cf9-f85d-4d04-b765-901f9a4116a6',
    name: 'Американская бурманская кошка',
  },
  {
    value: '5d146faa-f096-4371-b517-5d30e424c420',
    name: 'Американская жесткошерстная кошка',
  },
  {
    value: '56d18226-f908-4cde-8fbd-a3dbf78c2a95',
    name: 'Американская короткошерстная кошка',
  },
  {
    value: '5ee72fd4-c36c-45dc-b252-75ca5eb660cb',
    name: 'Американский бобтейл',
  },
  { value: '6595f901-2d33-4b7e-828c-7bb23d267b06', name: 'Американский кёрл' },
  { value: 'dc724969-5766-4e4e-a328-860b8aec7747', name: 'Анатолийская кошка' },
  { value: '99b400d4-8053-464c-9fbe-b3cefd9729f5', name: 'Аравийский мау' },
  {
    value: 'e21bfeed-d965-4414-8480-5f39d8a07ec2',
    name: 'Балинезийская кошка',
  },
  { value: '406c2376-c7cd-4812-aeac-3396d7fcbe4a', name: 'Бамбино' },
  { value: 'deac7bfa-d890-448c-b8f5-6021118ee5f0', name: 'Бенгальская кошка' },
  { value: 'd38e5781-a51e-4e0f-ae59-df872c524530', name: 'Бирманская кошка' },
  { value: '330856f7-bed0-4089-ab67-9ac29c625204', name: 'Бомбейская кошка' },
  {
    value: 'ebd0e23c-f349-4cb5-bf57-54b33a5bc2c5',
    name: 'Бразильская короткошерстная кошка',
  },
  {
    value: '52bd55a0-2fd7-4cfe-875c-3323d23f391b',
    name: 'Британская короткошерстная',
  },
  { value: '2f8b58eb-71e2-427e-9b93-e8ea051f3617', name: 'Бурманская кошка' },
  { value: 'dd899f7a-520d-4d83-aa28-91b5553726f9', name: 'Бурмилла' },
  { value: 'df124349-32f4-4425-a39e-ed6b986bc785', name: 'Гавана' },
  { value: 'f9f9e784-e310-4d36-9644-2498f2ffba87', name: 'Гималайская кошка' },
  { value: 'a0c93e10-5c2b-4b0f-a0ec-c132bb86a4e9', name: 'Девон-рекс' },
  { value: '1b5c24b5-5c4b-44b1-ac4f-48160647040b', name: 'Донской сфинкс' },
  {
    value: '9b5c5594-a88f-468e-afd5-ff67d53740ca',
    name: 'Европейская короткошерстная (кельтская)',
  },
  { value: '9af28eb8-0b11-49b8-b770-ed78954a640e', name: 'Египетская мау' },
  {
    value: 'c5b3282a-38a1-45a7-8828-d708db13c38b',
    name: 'Йоркская шоколадная кошка',
  },
  {
    value: 'd448115a-f19f-4430-9684-554ecbe0f2ac',
    name: 'Калифорнийская сияющая кошка',
  },
  { value: '05913f1e-8f18-487b-ad8e-8ea77f8df046', name: 'Канаани' },
  { value: 'a0d37ee9-c410-46b7-a438-8261f865a60e', name: 'Канадский сфинкс' },
  { value: 'f6780ab0-c244-4d5b-9e44-46a02ef41c97', name: 'Карельский бобтейл' },
  { value: '46795600-ff9a-48d3-832c-ef52634e04f8', name: 'Кимрик' },
  { value: 'edf9c232-4344-4060-9fca-a4154e9cc7de', name: 'Корат' },
  { value: '416bbce5-9f9d-46ff-bf5e-2dd1f4f088d4', name: 'Корниш рекс' },
  { value: 'b7cc2147-b704-4b76-adc5-8c4027f5676f', name: 'Курильский бобтейл' },
  { value: '10ad823c-54e0-407e-a300-2450ea4a6de7', name: 'Лаперм' },
  { value: 'b5813524-4894-412f-a60b-cc946965e026', name: 'Ликои' },
  { value: 'a733ff1a-09aa-4405-a249-d44c7a753425', name: 'Манчкин' },
  { value: '002abd1b-0404-4b9d-b713-061c562bca82', name: 'Мейн-кун' },
  { value: 'c98b67ab-bce0-444e-b768-7574b5cf0e3d', name: 'Меконгский бобтейл' },
  { value: '206491e7-3f8f-4118-b4d2-298586e58487', name: 'Минскин' },
  {
    value: '641d9a31-528a-4da1-b47a-0feb26bf61d4',
    name: 'Мэнкс или мэнская кошка',
  },
  { value: '73fb3b9a-979c-4050-9c6d-96cc958dc14b', name: 'Наполеон (менуэт)' },
  {
    value: 'a4aced84-b224-4b3c-b5d8-fe324a1e4ba6',
    name: 'Невская маскарадная',
  },
  { value: 'a7a4e1a6-6d58-4681-b936-2ef040d7d7c2', name: 'Немецкий рекс' },
  { value: '210154ad-20d2-4f7b-9392-d19cdc90aaa8', name: 'Нибелунг' },
  {
    value: '3333a0c7-ae6f-4335-bd8c-56b027046ce0',
    name: 'Норвежская лесная кошка',
  },
  { value: 'f123301b-d196-481c-9bee-f5247fe522e6', name: 'Орегон-рекс' },
  { value: '05618c37-4833-4bfd-8de5-342ca15a5b97', name: 'Ориентальная кошка' },
  { value: '957921d8-6d1b-4641-a39a-e90ede68ce60', name: 'Охос азулес' },
  { value: '2b3a02da-3266-4b0a-ba16-be07655631cf', name: 'Оцикет' },
  { value: 'e9890616-4e15-4ca7-99b6-4a7ef9876dc0', name: 'Персидская кошка' },
  {
    value: 'b747adab-0612-486a-820a-417c589c68c6',
    name: 'Петерболд или Петербургский сфинкс',
  },
  { value: '2fcd55c9-4d0e-4feb-ae7f-720fed6f806b', name: 'Пиксибоб' },
  { value: '287febaa-1875-4f34-b897-327cd97b393f', name: 'Рагамаффин' },
  {
    value: '22b73172-183d-44f8-b0a9-3fef24678c57',
    name: 'Русская голубая кошка',
  },
  { value: '8b0c97c3-5371-4818-9b75-cd77aec99734', name: 'Рэгдолл' },
  { value: 'adcc9563-8da5-429c-892f-70280822e92e', name: 'Саванна' },
  { value: 'd2ebfe1f-4e4d-4c59-ab89-3ed798506444', name: 'Сейшельская кошка' },
  { value: 'd6e2389b-7a98-473f-8ce5-5154604b1e42', name: 'Селкирк-рекс' },
  { value: '502195bc-a1d6-428b-84aa-2e69f6471af1', name: 'Серенгети' },
  { value: '90f44bf8-82ea-4812-b87d-c955b0130119', name: 'Сиамская кошка' },
  { value: '08edc314-b459-413a-9350-5d8a47dc0e0f', name: 'Сибирская кошка' },
  { value: '2d9fd5db-f20b-49d3-bb12-fa854b991420', name: 'Сингапурская кошка' },
  { value: '68ce4c42-07b8-4a63-ba2b-1531a1bfe385', name: 'Скоттиш страйт' },
  { value: 'eddf5238-e106-431a-86e4-070e0403fda2', name: 'Сноу-шу' },
  { value: '8c5dd1ec-191a-4af5-b40b-75c43207d36c', name: 'Сококе' },
  { value: 'a44657a0-21b4-4704-bc40-f80fb144b2dd', name: 'Сомалийская кошка' },
  { value: '7af0ae77-3f8d-4800-b541-2fb60f14fe94', name: 'Тайская кошка' },
  { value: '0820535b-78aa-4ee0-a0f7-771fbbbf399d', name: 'Тойгер' },
  { value: '2f8f35b6-5ffc-4a79-96bd-9ed8917d2712', name: 'Тонкинская кошка' },
  { value: 'd05156ca-182e-4319-8217-7e0a7e9f91e9', name: 'Турецкая ангора' },
  { value: '2e345906-d3ab-4b49-a9e8-128106e54d0d', name: 'Турецкий ван' },
  { value: 'befb7f24-ff84-475b-8f1c-96115864cdae', name: 'Украинский левкой' },
  { value: '3ae1b049-4f97-4bcd-b44e-4c3f026871de', name: 'Уральский рекс' },
  { value: '857706c3-840d-42d1-8789-01a67712f1ad', name: 'Форин вайт' },
  { value: '94fedbf5-2e19-4903-bfb6-59c70a8bea23', name: 'Хайленд-фолд' },
  { value: '84e3b539-caf1-4c3d-958e-cc499d169840', name: 'Цейлонская кошка' },
  { value: '694ac01f-306e-413b-b657-b66e14e1f38a', name: 'Чаузи' },
  { value: 'b27d6085-ad04-48c7-b9f4-e861ba6da648', name: 'Шантильи тиффани' },
  { value: '558c2ab8-dfd3-45b7-8511-60c1cc7c75ce', name: 'Шартрез' },
  {
    value: 'b99cac60-18eb-47b8-ba44-3f75b1d0af9a',
    name: 'Шотландская вислоухая',
  },
  { value: 'df9f68e3-ddde-4c5f-8e45-1228b0809950', name: 'Эгейская кошка' },
  {
    value: 'a3171737-f414-4d92-8872-2f767a50f47d',
    name: 'Экзотическая короткошерстная',
  },
  {
    value: '68e71455-f19c-41d1-8ecd-d445eefb9785',
    name: 'Яванез или Яванская кошка',
  },
  { value: '0cc3185c-1306-481e-ad4c-0b136b94d3b0', name: 'Японский бобтейл' },
];

export const petBreedDog: SelectInterface[] = [
  {
    value: '964b5c6b-3d68-4d50-a285-249a310b69b6',
    name: 'Американский бульдог',
  },
  {
    value: '94579d37-3d71-4e8b-ae3f-ad83bf5cd7d8',
    name: 'Австралийская короткохвостая пастушья собака',
  },
  {
    value: '86f89c0e-dac9-483c-b23f-211a630e602a',
    name: 'Австралийская пастушья собака',
  },
  {
    value: '39d210e2-c80e-488d-b925-4bfd15e470fe',
    name: 'Австралийский келпи',
  },
  {
    value: 'ffe52916-f923-4da2-8cd2-2c4ec901b90b',
    name: 'Австралийский терьер',
  },
  { value: '846eabee-9e1f-4d1a-82fd-0e630b51be10', name: 'Австрийский пинчер' },
  { value: 'cde1bc8a-def5-4d1c-af99-0b2f331ce31e', name: 'Австрийская гончая' },
  {
    value: 'e82eda84-8d95-4750-8ec1-56e5fe6a8124',
    name: 'Австрийский брудастый бракк',
  },
  { value: '9977baad-c867-41cf-8862-3c37769c3e34', name: 'Азавак' },
  {
    value: '97f04e1e-ab4a-459b-9c10-be8ebbf1af3b',
    name: 'Азорская пастушья собака',
  },
  { value: 'd35f5f83-3161-4de9-a373-e642816fccb3', name: 'Аиди' },
  { value: '04b26b60-e830-4b8b-b699-fa6e5e19cac0', name: 'Акита-ину' },
  {
    value: '02573a66-d962-46c8-a302-971e032f563d',
    name: 'Алабай или среднеазиатская овчарка',
  },
  { value: 'd539a24d-d1a5-4421-bbb7-a80a786da3ab', name: 'Алано' },
  { value: '32e03d45-c280-41ef-b59b-4278ed4f94df', name: 'Алапахский бульдог' },
  { value: '61e8091d-2206-4910-bb12-5f97a3e775aa', name: 'Алопекис' },
  {
    value: 'fb768607-a2d9-43b7-9117-e9632a2491ad',
    name: 'Альпийская таксообразная гончая',
  },
  {
    value: '17394b47-49a7-4541-ad8a-566dfac6f89a',
    name: 'Аляскинский кли кай',
  },
  {
    value: 'a8ec7432-d6a4-49fc-9cd8-aa3b8f40626a',
    name: 'Аляскинский маламут',
  },
  { value: 'b3e499fb-28db-4bf3-bbeb-6d376b6bb7ae', name: 'Американская акита' },
  {
    value: '40e6519e-e196-48d4-ba79-1702ac69dd06',
    name: 'Американская эскимосская собака',
  },
  {
    value: 'ee98a4d1-09a0-46e1-8cc2-f3f44772e149',
    name: 'Американский голый терьер',
  },
  {
    value: 'e67fde03-8ec1-49ce-8cc4-17076b63c9bd',
    name: 'Американский бандог',
  },
  {
    value: 'da4b8189-c191-41eb-aebd-4b158f2fe10d',
    name: 'Американский бульдог',
  },
  {
    value: 'cbb54b73-6881-44d7-a2ce-8b9ab791c93a',
    name: 'Американский водяной спаниель',
  },
  {
    value: '5db4df96-6b47-45be-a7b1-736a8fe288e3',
    name: 'Американский кокер-спаниель',
  },
  {
    value: '9b254f56-15a3-4a34-802b-77d195c1137e',
    name: 'Американский мастиф',
  },
  {
    value: '068eeb81-3eb1-46ef-a7a9-793f67e7953b',
    name: 'Американский питбультерьер',
  },
  {
    value: '2c9c23c4-b9b4-4cce-94b7-5a516a0e4447',
    name: 'Американский стаффордширский терьер',
  },
  {
    value: 'ff163b63-05a6-4377-8ee2-76222aed0338',
    name: 'Американский той-фокстерьер',
  },
  {
    value: '0b6774b7-3062-4883-84ef-203069a3def1',
    name: 'Американский фоксхаунд',
  },
  {
    value: '22c67168-9e23-400b-b694-7ffce29309a8',
    name: 'Анатолийская овчарка',
  },
  {
    value: 'b477b317-9cd8-4a8d-9378-451897e316f9',
    name: 'Английская енотовая гончая',
  },
  { value: '656f813c-7226-498c-b94f-a078fb984299', name: 'Английская овчарка' },
  { value: 'c873eed4-430c-4ef4-80ee-4aeb468c065a', name: 'Английский бульдог' },
  {
    value: 'c2c1b3d6-fcdf-47d9-b813-f567d9dcde7c',
    name: 'Английский водяной спаниель',
  },
  {
    value: '40022383-216e-4bb8-90da-6a5451905c45',
    name: 'Английский кокер-спаниель',
  },
  { value: 'b668408b-da8e-4d3a-91d9-c82759a73139', name: 'Английский мастиф' },
  { value: '2b27679e-4297-47ca-82d9-19d846cacd15', name: 'Английский сеттер' },
  {
    value: '8e16d116-abec-43ec-93f4-fe20fd7d36e7',
    name: 'Английский спрингер-спаниель',
  },
  {
    value: 'e6e37459-fa02-4dcf-bfdc-9df082281f3d',
    name: 'Английский той-терьер',
  },
  {
    value: '331b6d92-569e-4ea5-bf03-83d70faad07c',
    name: 'Английский фоксхаунд',
  },
  {
    value: '2e2a9f83-51b9-47f0-8088-dec6f76d1ea4',
    name: 'Англо-французская малая гончая',
  },
  {
    value: 'aa28480a-39d8-4f60-9551-28cac23bf897',
    name: 'Аппенцеллер зенненхунд',
  },
  { value: 'ade12235-694a-4f6c-8991-da4cb8b8481c', name: 'Аргентинский дог' },
  { value: 'f7ed581e-2389-4358-a3b8-8f3561370605', name: 'Арденнский бувье' },
  { value: 'f4a53944-86a5-450a-8260-3be7cfc913e3', name: 'Артуазская гончая' },
  { value: '69a3f67e-8a4a-4fd4-a308-c7ccb422089f', name: 'Аусси' },
  { value: '1dbfaaf8-2ad4-4bcb-887e-3462aa69d678', name: 'Афганская борзая' },
  { value: 'b756afe1-3178-4b12-abd3-9d00ff09f3e4', name: 'Аффенпинчер' },
  {
    value: 'a1216e42-79cd-46f4-870b-a83dd132cc63',
    name: 'Баварская горная гончая',
  },
  { value: '6f6b6173-e1c4-4272-94cc-0bca0f8adf4d', name: 'Бакхмуль' },
  { value: '7795d228-c1d7-488b-b969-0905daf81f6d', name: 'Барбет' },
  { value: '8de2b2e1-ca4c-4d10-90d4-8f4131ca646f', name: 'Басенджи' },
  { value: '43937bb4-9e59-4f44-b31e-244564ffcb4c', name: 'Баскская овчарка' },
  { value: '5e855880-806e-4419-b7a4-20ef13b4dffb', name: 'Бассет-хаунд' },
  { value: 'c239a64c-fcb1-40a2-936f-a186bda4e1ad', name: 'Бедлингтон-терьер' },
  {
    value: '021961c1-9578-404a-af43-d49edc9a246c',
    name: 'Белая швейцарская овчарка',
  },
  {
    value: '517db1f1-baa0-4b19-8aa8-6ddd902aaa42',
    name: 'Бельгийская овчарка',
  },
  {
    value: '809ed552-5a5a-4f88-8e37-99d6e71459bc',
    name: 'Бельгийский гриффон',
  },
  { value: '4c9a9242-1f37-4c9f-9ba1-ffac922f0990', name: 'Бергамская овчарка' },
  { value: 'dd903109-8dc2-4676-8cea-fc07230a8278', name: 'Бернская гончая' },
  {
    value: '753e2e0b-7b99-4e4d-b4e2-35ba3c228e50',
    name: 'Бернский зенненхунд',
  },
  {
    value: '91bbadd9-1af3-4b88-a8dc-b6c428345e3c',
    name: 'Бивер йоркширский терьер',
  },
  { value: 'dbef3994-806f-4d9f-bc30-51a55947cf5c', name: 'Бигль' },
  { value: '69ba1566-34e0-4452-bc92-1776be9afb49', name: 'Бишон фризе' },
  { value: '25c84570-5c28-471b-b940-478b0393bcb7', name: 'Бладхаунд' },
  { value: '054bdfb6-f0fc-4bac-b9b8-39d4d5c3aaea', name: 'Блю-лейси' },
  { value: '93ebca55-2d50-412d-847f-1f980c5b9376', name: 'Бобтейл' },
  { value: '9fd28587-0bfd-467f-886f-34767efc8fe1', name: 'Болгарская овчарка' },
  { value: '92680d3a-a69c-4795-b37e-8f5871b52dc0', name: 'Болгарский барак' },
  { value: 'b8218bfb-f1c7-4115-b368-a7c00f7a5481', name: 'Болоньез' },
  {
    value: '20592465-8231-4420-893d-519f27e30e7f',
    name: 'Большой вандейский бассет-гриффон',
  },
  {
    value: 'fedf20fc-96fd-4d79-bac4-9e595c87a222',
    name: 'Большой вандейский гриффон',
  },
  {
    value: 'edf3d474-4113-46fc-b59d-b828551871e8',
    name: 'Большой швейцарский зенненхунд',
  },
  { value: '36dbf519-8f70-42ca-a55e-abcaa7ef12d6', name: 'Бордер-колли' },
  { value: 'b8dcab1a-a45f-4a57-8d0a-6577287e0b1c', name: 'Бордер-терьер' },
  { value: '7bb356de-3f7a-480b-8796-70d8401c52ea', name: 'Бордоский дог' },
  { value: '12df93e6-0f38-4ac2-bcc1-75439de4efd1', name: 'Бородатый колли' },
  { value: '452540c2-f1ef-458a-a87b-b85db9fa3aab', name: 'Босерон' },
  { value: '610cf696-4b3b-47a4-b453-aaf26a6f7fa8', name: 'Бостонский терьер' },
  { value: '0d0e563e-b149-4f48-ab67-51793e0860f6', name: 'Бразильский терьер' },
  {
    value: '0765876a-4650-43ac-9192-a465421fa4b3',
    name: 'Бретонский эпаньоль',
  },
  { value: '398c85dc-223a-4bf4-b180-584a86557f58', name: 'Бриар' },
  { value: '8565bcd1-d7ce-44c8-b51c-fb2d46ce860b', name: 'Брохольмер' },
  {
    value: 'debccee9-7282-4b4a-a7e4-0a32a5204a26',
    name: 'Брюссельский гриффон',
  },
  {
    value: '02f095a5-9f12-47f6-b94a-e1fd129eee61',
    name: 'Буковинская овчарка',
  },
  { value: 'ebbb0895-3644-4ac1-8d8f-488bc69281f7', name: 'Булли кутта' },
  { value: '932332cd-8215-4036-b37b-6a52f41bc1d4', name: 'Бульдог кампейро' },
  { value: 'd637b34f-84ca-42b7-bb52-dafbf983a2d4', name: 'Бульдог Катахулы' },
  { value: '7d338a8b-74e1-4c49-97b0-9313a73fafb5', name: 'Бульмастиф' },
  { value: 'a9e47224-221d-4825-94c3-e30cac3fbaa6', name: 'Бультерьер' },
  {
    value: 'edb7b781-0eca-4645-af47-8f8062633c80',
    name: 'Бультерьер миниатюрный',
  },
  { value: 'd5ea3a49-d22a-46b2-95a3-d730fa98047f', name: 'Бурбуль' },
  {
    value: '5efed4a5-2569-4862-b531-a9e7a786aa11',
    name: 'Бурят-монгольский волкодав',
  },
  { value: '5c452410-a9df-4024-b26b-9b3078303124', name: 'Валенсийский ратер' },
  { value: '7c8af007-e528-40e5-a2a9-f2a7cbcbab16', name: 'Веймаранер' },
  { value: 'c9515443-b3f8-4cb0-9670-7a4409c9be6c', name: 'Вельш-корги' },
  {
    value: '4a7d6454-3a4b-4053-af1b-4173fa1cd484',
    name: 'Вельш-спрингер-спаниель',
  },
  { value: '3c3cdb72-9fe2-4be1-9d0b-ec1aea31eb62', name: 'Вельштерьер' },
  { value: 'f45698b9-8942-43eb-ad73-fb259d49fb8b', name: 'Венгерская борзая' },
  { value: '190313ed-8d01-4ad4-9ee1-70e56d85d16c', name: 'Венгерская выжла' },
  {
    value: '67c601b4-fb04-49e3-8447-c8a0d8faaa07',
    name: 'Вест-хайленд-уайт-терьер',
  },
  { value: '2ea1bbe2-4c7c-4d2a-a5b7-146fcf93ec6a', name: 'Веттерхун' },
  {
    value: '7515d185-01ac-464b-a133-b5314b01ba7a',
    name: 'Волчья собака сарлоса',
  },
  { value: '015176dd-f0b5-4812-83a9-5fd4e389da29', name: 'Вольпино итальяно' },
  {
    value: '20646f74-1aa8-4089-8e21-1015365c8dce',
    name: 'Восточноевропейская овчарка',
  },
  {
    value: '70ca69ce-2e4d-486e-8dbc-6858ab4feb9f',
    name: 'Восточносибирская лайка',
  },
  { value: 'ceb39614-4453-463e-a166-eb3e74b31805', name: 'Гаванский бишон' },
  {
    value: 'fafc5eed-c6f5-4857-92e6-9ab46edce912',
    name: 'Гампр (Армянский волкодав)',
  },
  { value: '2445b864-1ade-4f8e-a76b-5e4fff4c1d27', name: 'Голдаст йорк' },
  { value: 'c4d616e9-1cda-46b5-92e0-6398249146b1', name: 'Грейхаунд' },
  { value: 'de12f0dd-b32b-467e-aeb0-d909ea55035c', name: 'Далматин' },
  { value: 'c7bbefe5-979b-4a10-a2ad-ae46553cfe00', name: 'Джек-рассел-терьер' },
  {
    value: 'fcfc715d-5764-4b7d-a005-7dfe1b406fea',
    name: 'Длинношерстный колли',
  },
  { value: '13fccb79-84b2-4f08-b1f4-42a41879992a', name: 'Доберман' },
  { value: 'c5a2bd16-bb91-4e32-af8d-18d9f76af570', name: 'Дратхаар' },
  {
    value: '665ce4bf-ee7e-496e-95d7-4edd42aad05d',
    name: 'Западно-сибирская лайка',
  },
  {
    value: '0cf7b1b2-3031-4210-a613-b7cf2c3d1025',
    name: 'Золотистый ретривер',
  },
  { value: '9e9548cc-2a78-4bd0-9097-4ee8d8c3bc30', name: 'Ирландскиий сеттер' },
  {
    value: '720e818c-e2b3-46ba-b37d-12873ad3fed7',
    name: 'Ирландский волкодав',
  },
  {
    value: '20377ec8-3f67-4f80-a2b8-a27ff07f45d4',
    name: 'Ирландский мягкошерстный пшеничный терьер',
  },
  { value: '5bb05c93-10bf-4e38-b10c-ca1d1629fbcc', name: 'Испанский мастиф' },
  { value: 'b750e68a-ac03-4f1e-a1fe-8555aa9a57ad', name: 'Йоркширский терьер' },
  { value: 'd1e13f87-63f3-4833-9be1-d723a0c5bc24', name: 'Ка-де-бо' },
  {
    value: '18126b23-d85e-4b1f-8e2c-d6de5d129d3b',
    name: 'Кавалер кинг чарльз спаниель',
  },
  { value: 'e58703c0-b2de-4cd8-b30e-78d6daf8d1cd', name: 'Кавказская овчарка' },
  {
    value: 'fae71f5f-bee6-44b9-843d-da513d3c503e',
    name: 'Кай-ину или тигровая собака',
  },
  { value: 'e9f03280-ad59-4c37-8ef2-559cc1599231', name: 'Канарский дог' },
  { value: '779d8f88-5083-47cd-8124-941a0bf073cb', name: 'Кане корсо' },
  { value: '976af2fc-becf-46ff-9de0-3f8a3973aa6f', name: 'Кеесхонд' },
  { value: '4e0ce6cf-a7ad-46e5-bb4f-5eaee3b8009c', name: 'Керн-терьер' },
  { value: 'a91b921e-0190-4600-ba09-48b500232409', name: 'Керри блю терьер' },
  { value: '7e16ff2b-ee3c-4045-9b8c-6d856ee2f1ed', name: 'Киргизская борзая' },
  { value: 'f09c9631-e405-442f-8d9f-904d5087ad09', name: 'Китайская хохлатая' },
  { value: '667c5649-3786-41eb-8ba2-76bc8f7280ba', name: 'Кламбер-спаниель' },
  { value: '8ebec123-2535-406a-851e-64098d42dc6c', name: 'Комондор' },
  { value: '1e106fff-2d11-4553-a780-50b5cfc86c76', name: 'Ксолоитцкуинтли' },
  { value: 'd41ffb56-1d5e-4280-99a3-3b4693e23d41', name: 'Кувас' },
  { value: 'bb62b298-d9f0-489f-b194-63b9c17208f3', name: 'Курцхаар' },
  {
    value: '95a5bd3d-dbfb-45a0-97a8-fb5853537e5e',
    name: 'Курчавошерстный ретривер',
  },
  { value: '07ce20ab-f559-4d8f-ae89-4d34bda00324', name: 'Лабрадор ретривер' },
  { value: 'c49026c2-7009-49e2-bd7b-059dc0fc0195', name: 'Лайка якутская' },
  { value: 'ba502605-a7a3-4e2f-b531-d41444f2938a', name: 'Ландсир' },
  { value: 'd9d6b39a-938e-4ca3-8698-be05d55ee3a0', name: 'Левретка' },
  { value: 'a48ba9b7-427a-4b49-a83c-1481e1b59fad', name: 'Лейкленд-терьер' },
  { value: 'f1d40bfc-357c-47ff-872e-a05ded9e540c', name: 'Леонбергер' },
  { value: '4720812e-a86f-4d64-ac08-e0b1ba4d1940', name: 'Лхаса апсо' },
  {
    value: '0c688bc0-aaee-4306-9238-e83cb84e2b05',
    name: 'Малый вандейский бассет гриффон',
  },
  {
    value: '5a3a7ee4-5bbf-4bab-bbb1-09e3e9c3ce15',
    name: 'Мальтийская болонка',
  },
  {
    value: '0d314cf5-1899-422a-93ef-2c4cad60e3a9',
    name: 'Мареммо-абруццкая овчарка',
  },
  { value: '9c3bc04f-6a1b-4995-939f-dee16f52523a', name: 'Мастино' },
  { value: '6ceb5de1-3be0-407a-a414-123c31287173', name: 'Миттельшнауцер' },
  {
    value: 'fa2a8060-adc8-4bf6-84f6-fe96ea061a30',
    name: 'Монгольская овчарка',
  },
  { value: 'b4fc1ce3-07ff-404f-aeea-3be62e21afd2', name: 'Мопс' },
  {
    value: '324be509-1818-474f-893c-6030b2088054',
    name: 'Московская сторожевая',
  },
  { value: 'f10fdb6e-ebd5-4233-898c-77a0287830c4', name: 'Московский дракон' },
  { value: 'dc7b2ed3-baea-4893-bd7c-01288c5b21e4', name: 'Немецкая овчарка' },
  { value: '187d9197-d37b-498d-8758-01bf17362f6a', name: 'Немецкий боксер' },
  { value: '2bf40312-45ee-4c38-af27-6c4f1b5e6d48', name: 'Немецкий дог' },
  { value: 'f9e7148e-8e6b-4330-9ec3-8dcdb33fac52', name: 'Немецкий пинчер' },
  { value: '51173026-8c9b-4ef1-8b6d-e8df12cadef7', name: 'Немецкий ягдтерьер' },
  {
    value: '6ff021dd-8bb4-4e2c-901f-114a50334a42',
    name: 'Новошотландский ретривер',
  },
  { value: 'd3392d34-c988-48c9-b15b-5e8f0c5e55c8', name: 'Норвич-терьер' },
  { value: '9bb1ec02-d8ab-4de5-b4f4-a054a0e74c44', name: 'Норфолк-терьер' },
  { value: '42713b9a-6066-4ec9-bd53-eeb77bc6c257', name: 'Ньюфаундленд' },
  {
    value: '2305a7b5-af67-4946-9a1d-b1548ce1ea87',
    name: 'Орхидея петербургская',
  },
  { value: '37bc6f91-f2a9-4688-a959-6e67bcc07dd5', name: 'Папильон' },
  {
    value: 'c8cf4569-c205-45b7-89f5-37321d088739',
    name: 'Парсон-рассел-терьер',
  },
  { value: 'ecb8c90b-e783-4f16-9dff-4b65618aa747', name: 'Пекинес' },
  {
    value: 'a56622ec-66cb-4139-8226-340e5fa3e229',
    name: 'Перуанская голая собака',
  },
  {
    value: '69c96012-b27b-465c-8456-6992c952cfaf',
    name: 'Пиренейская горная собака',
  },
  { value: '5190f87f-889e-4942-9127-56fbfe1f9efd', name: 'Поденгу португезе' },
  { value: '6793bbfa-6d45-42e3-bf6b-f90dfd13e860', name: 'Пойнтер' },
  { value: 'd494bb5f-d5ba-47ca-876b-5632f8ae6447', name: 'Померанский шпиц' },
  { value: '713b45fc-9073-4b5d-b6d3-13c1c7398dda', name: 'Пражский крысарик' },
  { value: 'f0e30282-4666-4852-bc74-238477f464f2', name: 'Пти брабансон' },
  { value: '7dc7ef11-8627-4e44-9436-629308ab9f33', name: 'Пудель' },
  { value: '905b0205-0db0-419c-8108-5989c4b07f7f', name: 'Пули' },
  {
    value: '4abd270e-26ce-4cd3-9f28-7dc051b27dc2',
    name: 'Риджбек родезийский',
  },
  { value: '6be5c8e3-1f4e-4c72-bc8e-3f3f7024b8b8', name: 'Ризеншнауцер' },
  { value: '26be49ef-9d9c-419d-b37a-21a571c6d972', name: 'Ротвейлер' },
  {
    value: '742bc907-6bc2-4caf-b2a2-087d19a9d3e8',
    name: 'Русская псовая борзая',
  },
  {
    value: 'c647c548-9025-4237-ba95-5f07e7c5607e',
    name: 'Русская салонная собака',
  },
  {
    value: 'f1dbcb78-f39f-4601-837c-7d6221bcd5a9',
    name: 'Русская цветная болонка',
  },
  { value: 'a39a1b1c-5987-434e-92b4-525a8027421e', name: 'Русский спаниель' },
  { value: 'd85888e2-ae91-4d49-84b9-0b83d366338b', name: 'Русский той-терьер' },
  { value: '9f587fbe-b9b5-4cc1-8cdb-5f5999fcbc54', name: 'Салюки' },
  { value: 'b14a8a19-d88f-4370-8fe4-56afb32c4c14', name: 'Самоедская лайка' },
  { value: '9a94e5d5-c520-40fc-92fa-201727101697', name: 'Сенбернар' },
  {
    value: 'a58d599e-a129-41d9-91c7-257886bb70bd',
    name: 'Сиба-ину (Шиба-ину)',
  },
  { value: '2afac2ef-e167-4d79-904c-63905e724e10', name: 'Сибирский хаски' },
  { value: '889bff4e-42f6-4850-b0ea-3c40bda38a7e', name: 'Силихем-терьер' },
  { value: '61414353-c56b-4e53-b0b1-3baca91201a7', name: 'Силки терьер' },
  { value: 'c322aee8-a9de-42a5-9bc7-4fbaa204bf5a', name: 'Сицилийская борзая' },
  { value: '691c9d3b-ce7b-4aec-adc3-da76d19806df', name: 'Скайтерьер' },
  { value: 'f2ce920b-9a35-4927-9ce9-b22cabeaa035', name: 'Скотч-терьер' },
  { value: '5780099e-c70f-460b-a727-a9970b692721', name: 'Словацкий чувач' },
  {
    value: 'e2e77b75-7113-4fa2-bb31-a9437ecbaf4e',
    name: 'Стаффордширский бультерьер',
  },
  { value: 'c1aebd07-092c-47dc-9d21-aaf4c7efbba5', name: 'Суссекс-спаниель' },
  { value: '0e2da024-2a34-4183-ae22-08c074dc13aa', name: 'Тазы' },
  { value: 'ca8e7808-15d3-4847-b65d-3ce889c2709a', name: 'Тайский риджбек' },
  { value: '6209ebfc-dd79-4db7-ad6c-a283a71d74ba', name: 'Такса' },
  { value: '91cd2e5b-0adf-497c-97a8-91f7d9bad03f', name: 'Тибетский мастиф' },
  { value: 'ab15676e-4660-4616-b408-c9ed86960da6', name: 'Тибетский терьер' },
  { value: 'aec41eac-45f0-4bed-868d-11cb11bf664b', name: 'Тоса-ину' },
  { value: 'cc38eec5-9b15-420c-81a4-4efea143efb8', name: 'Уиппет' },
  { value: '708ec39d-9dc0-487c-90e5-ef7d6f596397', name: 'Фараонова собака' },
  { value: 'ae07fe52-9a7c-4df8-af87-71af0dbbbf29', name: 'Фила бразилейро' },
  { value: '7c7d3f4b-45da-48e8-b24f-84f1ff1e094f', name: 'Финский шпиц' },
  { value: 'a12e29b7-1d79-4287-abeb-a6adfc4195e5', name: 'Фландрский бувье' },
  { value: '5d6f1272-67a6-4b93-adad-c6831d4fc966', name: 'Фокстерьер' },
  {
    value: 'e1fbf4cc-c38c-43c7-aa55-d6c67b567497',
    name: 'Французский бульдог',
  },
  { value: '07f22077-3b97-40d9-81eb-a04278794d47', name: 'Ханаанская собака' },
  { value: '9563c415-d0ec-44f7-bd78-7211a1cef2bc', name: 'Ховаварт' },
  { value: '924ab271-3bfd-4282-bd87-253812119a85', name: 'Хоккайдо' },
  { value: '7d62ca46-711c-45b8-8951-c72b6d5a5e16', name: 'Цвергпинчер' },
  { value: 'd14ef574-0852-4d80-9a17-f0d68c7ded5e', name: 'Цвергшнауцер' },
  { value: '90635353-2ca4-4278-9c60-a49b3f913137', name: 'Чау-чау' },
  { value: 'e4260d63-5c10-40a9-b99d-f9033da40c11', name: 'Черный терьер' },
  {
    value: '9045e457-e502-4f85-835d-9b7fb3113dcc',
    name: 'Чесапик бей ретривер',
  },
  {
    value: '61d80b5e-6e81-448e-b54c-2cde7890ca3f',
    name: 'Чехословацкая волчья собака',
  },
  { value: '977c0066-0b88-4440-a312-c9a9e3bd26cf', name: 'Чихуахуа' },
  { value: 'ea1885f0-b0bd-404d-bc3a-67cf077c5c41', name: 'Шарпей' },
  { value: '5aaf184e-c588-4127-a98d-fd091078cdb6', name: 'Шелти' },
  { value: 'c37843c5-4396-4545-b85c-e4611e49656c', name: 'Ши-тцу' },
  { value: '309a9640-6f16-453b-a021-810f90c922d9', name: 'Шипперке' },
  { value: '5f7f0538-6c3e-41ab-b586-308ada445a94', name: 'Шотландский сеттер' },
  { value: 'a861b8c6-c508-408c-8e33-1c108fa41eb4', name: 'Шпиц малый' },
  { value: '8a76c9ff-0b6e-4e82-9c2a-3501ed8a0c10', name: 'Эрдельтерьер' },
  { value: '21d7743a-33a1-4868-b4ae-23679a6cd372', name: 'Эстонская гончая' },
  {
    value: '478710bf-8e10-4468-aaca-8b46befb23d3',
    name: 'Южнорусская овчарка',
  },
  { value: 'ee6623a6-847e-41bc-a03e-3618b98bacb4', name: 'Японский хин' },
  { value: 'c228b4fa-f055-4d73-8565-4882c5be373d', name: 'Японский шпиц' },
];
