import { Component, OnInit } from '@angular/core';
import { FileInterface } from '../../interfaces/file.interface';
import { UserService } from '../../services/user.service';
import { UserInterface } from '../../interfaces/user/user.interface';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AccountChangePhotoComponent } from '../account-change-photo/account-change-photo.component';

@Component({
  selector: 'app-account-sidebar',
  templateUrl: './account-sidebar.component.html',
  styleUrls: ['./account-sidebar.component.scss'],
})
export class AccountSidebarComponent implements OnInit {
  avatarFiles: FileInterface[];
  avatar: string;
  avatarId: string;
  showAvatarCap: boolean = true;
  userData: UserInterface;
  showMobileNavigation: boolean = false;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.userData = this.userService.getUserData().UserData;
    this.getUserAvatar();
  }

  getUserAvatar() {
    this.avatarFiles = this.userService.getUserData().Files;

    if (this.avatarFiles.length) {
      const last = this.avatarFiles.length - 1;

      const name = this.avatarFiles[last].Name;
      const content = this.avatarFiles[last].Content;
      this.avatarId = this.avatarFiles[last].Id;

      let filename: any = name.split('.');

      filename = filename[filename.length - 1];

      this.avatar = 'data:image/' + filename + ';base64,' + content;
      this.userService.userAvatar = this.avatar;

      this.showAvatarCap = false;
    } else {
      this.showAvatarCap = true;
    }
  }

  changePhoto() {
    const dialogRef = this.dialog.open(AccountChangePhotoComponent, {
      data: {
        avatar: this.avatar,
        avatarId: this.avatarId,
      },
      panelClass: 'change-photo-dialog',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getUserAvatar();
        this.snackBar.open('Фото успешно изменено!', 'Ок', {
          duration: 3000,
        });
      }
    });
  }

  toggleNavigation() {
    this.showMobileNavigation = !this.showMobileNavigation;
  }
}
