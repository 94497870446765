import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClinicPriceListInterface } from '../../shared/interfaces/clinic/price-list/clinic-price-list.interface';
import { ClinicService } from '../../shared/services/clinic.service';
import { SelectInterface } from '../../shared/interfaces/select.interface';
import {
  clinicActions,
  clinicServices,
  specializations,
  typeId,
} from '../../shared/catalogs/clinic.catalogs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../../../environments/api';
import { ClinicFullInterface } from '../../shared/interfaces/clinic/clinic-full.interface';
import { BaseService } from '../../shared/services/base.service';
import { Subscription } from 'rxjs/Subscription';
import { DialogService } from '../../shared/services/dialog.service';
import { ClinicEditResponseInterface } from '../../shared/interfaces/clinic/clinic-edit-response.interface';
import { RemoveAccountDetailInterface } from '../../shared/interfaces/clinic/remove-account-detail.interface';
import { isEmpty } from '../../shared/functions/functions';
import { DeleteAccountDetailResponseInterface } from '../../shared/interfaces/clinic/delete-account-detail-response.interface';
import { CurrentClinicResponseInterface } from '../../shared/interfaces/clinic/current-clinic-response.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clinic-price',
  templateUrl: './clinic-price.component.html',
  styleUrls: ['./clinic-price.component.scss'],
})
export class ClinicPriceComponent implements OnInit {
  directions: string[] = [];

  preloader: boolean = true;

  selectClinicActions: SelectInterface[] = clinicActions;
  selectClinicServices: SelectInterface[] = clinicServices;
  selectTypeId: SelectInterface[] = typeId;
  filteredOptions: SelectInterface[] = [...this.selectClinicServices];
  forRemove: RemoveAccountDetailInterface = {};

  form: FormGroup;
  clinicEditSubscribe: Subscription;
  clinicRemoveSubscribe: Subscription;
  getCurrentClinicSubscribe: Subscription;

  accountId: string = this.clinicService.clinicFullData.AccountData.AccountId;

  constructor(
    private clinicService: ClinicService,
    private baseServices: BaseService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit() {
    this.initForm();
    this.initDirections();
    this.initPriceList();
  }

  private _filter(value: string): Object[] {
    const filterValue = value.toLowerCase();

    return this.selectClinicServices.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  changeAutoFill(event) {
    this.filteredOptions = this._filter(
      event.target.value
    ) as SelectInterface[];
  }

  displayFn(options: SelectInterface[]): (value) => string | null {
    return (value) => {
      const correspondingOption = Array.isArray(options)
        ? options.find((option) => option.value === value)
        : null;

      let returnValue = value ? value : '';

      return correspondingOption ? correspondingOption.name : returnValue;
    };
  }

  initDirections() {
    let fullDirection = this.clinicService.getClinicFullData()
      .AccountDirections;

    fullDirection.forEach((item) => {
      this.directions.push(item.Action);
    });

    this.directions = this.directions.filter((item, index) => {
      return this.directions.indexOf(item) === index;
    });
  }

  initPriceList() {
    const priceList: ClinicPriceListInterface[] = this.clinicService.getClinicFullData()
      .AccClinicService;

    const parent: FormArray = this.form.get('directions') as FormArray;

    for (let i = 0; i < this.directions.length; i++) {
      if (!parent.controls[i]) {
        parent.push(this.initFormServices());
      }

      let j = 0;

      for (let item of priceList) {
        if (item.Action === this.directions[i]) {
          const controls: FormArray = parent.controls[i].get(
            'services'
          ) as FormArray;

          let service;

          if (item.ClinicService) {
            service = item.ClinicService;
          } else {
            service = item.CustomText;
          }

          controls.push(
            new FormGroup({
              id: new FormControl(item.Id),
              action: new FormControl(item.Action, [Validators.required]),
              clinicService: new FormControl(service, [Validators.required]),
              cost: new FormControl(item.Cost, [Validators.required]),
              type: new FormControl(item.TypeId, [Validators.required]),
            })
          );

          controls.controls[j].get('clinicService').patchValue(service);

          j++;
        }
      }
    }

    this.preloader = false;
  }

  initForm() {
    this.form = new FormGroup({
      directions: new FormArray([]),
    });
  }

  initFormServices() {
    return new FormGroup({
      services: new FormArray([]),
    });
  }

  initFormService(action) {
    return new FormGroup({
      id: new FormControl(null),
      action: new FormControl(action, [Validators.required]),
      clinicService: new FormControl(null, [Validators.required]),
      cost: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
    });
  }

  addFormService(i) {
    const parent: FormArray = this.form.get('directions') as FormArray;
    const controls: FormArray = parent.controls[i].get('services') as FormArray;

    controls.push(this.initFormService(this.directions[i]));
  }

  getFormServices(form) {
    return form.controls.services.controls;
  }

  getFormDirections(form) {
    return form.controls.directions.controls;
  }

  removeFormService(i, j) {
    const controls: FormArray = this.form.get([
      'directions',
      i,
      'services',
    ]) as FormArray;

    const id = controls.controls[j].get('id').value;

    if (id) {
      if (!this.forRemove.AccClinicService)
        this.forRemove.AccClinicService = [];

      this.forRemove.AccClinicService.push({
        Id: id,
      });
    }

    controls.removeAt(j);
  }

  onSubmit() {
    !api.production ? console.log('form', this.form) : null;

    const values = this.form.value;

    let services: ClinicPriceListInterface[] = [];

    for (let direction of values.directions) {
      for (let price of direction.services) {
        const noCustom = this.selectClinicServices.find(
          (option) => option.value === price.clinicService
        );

        let service = {
          Id: price.id,
          Action: price.action,
          Cost: price.cost,
          TypeId: price.type,
        };

        noCustom
          ? (service['ClinicService'] = price.clinicService)
          : (service['CustomText'] = price.clinicService);

        services.push(this.baseServices.clearEmpty(service));
      }
    }

    let request: ClinicFullInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AccClinicService: services,
      AccountData: {
        AccountId: this.clinicService.getClinicFullData().AccountData.AccountId,
      },
    };

    if (isEmpty(this.forRemove)) {
      this.savePriceList(request);
    } else {
      this.deleteFromPrice(request);
    }

    !api.production ? console.log('request', request) : null;
  }

  deleteFromPrice(request) {
    const timeout = setTimeout(() => {
      this.dialogService.showStandardError();
      this.clinicRemoveSubscribe.unsubscribe();
    }, api.requestTimeout);

    this.clinicRemoveSubscribe = this.clinicService
      .deleteAccountDetail(this.forRemove)
      .subscribe(
        (response: DeleteAccountDetailResponseInterface) => {
          clearTimeout(timeout);
          !api.production
            ? console.log('DeleteAccountDetailResult', response)
            : null;

          if (response.DeleteAccountDetailResult.Success) {
            this.savePriceList(request);
          } else {
            this.dialogService.showStandardError();
          }
        },
        () => {
          clearTimeout(timeout);
          this.dialogService.showStandardError();
        }
      );
  }

  savePriceList(request) {
    const timeout = setTimeout(() => {
      this.dialogService.showStandardError();
      this.clinicEditSubscribe.unsubscribe();
    }, api.requestTimeout);

    this.clinicEditSubscribe = this.clinicService.editClinic(request).subscribe(
      (response: ClinicEditResponseInterface) => {
        clearTimeout(timeout);
        !api.production ? console.log('clinicEditResult', response) : null;

        if (response.EditAccountResult.Success) {
          this.clinicService.openStandardDialog(
            'Вы успешно сохранили прайс лист',
            'Вы изменили прайс лист клиники, теперь новая информация будет доступна всем пользователям!'
          );

          this.getNewClinicData();
        } else {
          this.dialogService.showStandardError();
        }
      },
      () => {
        clearTimeout(timeout);
        this.dialogService.showStandardError();
      }
    );
  }

  getNewClinicData() {
    const timeout = setTimeout(() => {
      this.showRefreshError();
      this.getCurrentClinicSubscribe.unsubscribe();
    }, api.requestTimeout);

    this.preloader = true;

    this.getCurrentClinicSubscribe = this.clinicService
      .currentClinic(this.accountId)
      .subscribe(
        (response: CurrentClinicResponseInterface) => {
          clearTimeout(timeout);
          !api.production ? console.log('clinicEditResult', response) : null;

          if (response.CurrentClinicResult.Success) {
            this.clinicService.setClinicFullData(response.CurrentClinicResult);
            this.clearAll();
            this.initDirections();
            this.initPriceList();
            this.filteredOptions = this.selectClinicServices;
          } else {
            this.showRefreshError();
          }
        },
        () => {
          clearTimeout(timeout);
          this.showRefreshError();
        }
      );
  }

  showRefreshError() {
    this.dialogService
      .openStatusDialog(
        'При обновлении данных произошла ошибка',
        'Ваши изменения были сохранены, но при обновлении данных произошла ошибка. Попробуйте заново открыть страницу.',
        true,
        'back'
      )
      .subscribe((response) => {
        if (response === 'back')
          this.router.navigate([
            '/clinic',
            this.clinicService.activeClinic,
            'info',
          ]);
      });
  }

  clearAll() {
    this.forRemove = {};
    this.directions = [];

    const directions: FormArray = this.form.get('directions') as FormArray;

    while (directions.length !== 0) {
      directions.removeAt(0);
    }
  }
}
