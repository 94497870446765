import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value == 'Male') {
      return 'Мужской';
    } else if (value == 'Female') {
      return 'Женский';
    } else {
      return 'Не указано';
    }
  }
}
