import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BaseService } from './base.service';
import { api } from '../../../environments/api';
import { ClinicFullInterface } from '../interfaces/clinic/clinic-full.interface';
import { UserService } from './user.service';
import { UserRequestInterface } from '../interfaces/user/user-request.interface';
import { ClinicInterface } from '../interfaces/clinic/clinic.interface';
import { AddSpecialistInterface } from '../interfaces/clinic/add-specialist.interface';
import { GetClinicSpecialistsInterface } from '../interfaces/clinic/get-clinic-specialists.interface';
import { MatDialog } from '@angular/material';
import { DialogInterface } from '../interfaces/dialog.interface';
import { AccountDialog } from '../../account/account.component';
import { CurrentSpecialistScheduleInterface } from '../interfaces/clinic/schedules/current-specialist-schedule.interface';
import { ClinicSpecialistScheduleInterface } from '../interfaces/clinic/schedules/clinic-specialist-schedule.interface';
import { IdInterface } from '../interfaces/clinic/schedules/id.interface';
import { GetAppointmentsFiltersInterface } from '../interfaces/clinic/schedules/get-appointments-filters.interface';
import { ClinicRegisterSpecialistInterface } from '../interfaces/clinic/register-specialist/clinic-register-specialist.interface';
import { ClinicGetInvitedSpecialistInterface } from '../interfaces/clinic/invited-specialist/clinic-get-invited-specialist.interface';
import { PreClinicRegisterSpecialistInterface } from '../interfaces/clinic/register-specialist/pre-clinic-register-specialist.interface';
import { RemoveAccountDetailInterface } from '../interfaces/clinic/remove-account-detail.interface';
import { ClinicFullRemoveInterface } from '../interfaces/clinic/clinic-full-remove.interface';
import { AppointmentRegisterUserDataInterface } from '../interfaces/appointments/appointment-register-user-data.interface';
import { AppointmentRegisterUserInterface } from '../interfaces/appointments/appointment-register-user.interface';
import { AppointmentRegisterPetDataInterface } from '../interfaces/appointments/appointment-register-pet-data.interface';
import { AppointmentRegisterPetInterface } from '../interfaces/appointments/appointment-register-pet.interface';
import { AppointmentAddInterface } from '../interfaces/appointments/appointment-add.interface';
import { AppointmentAddUserDataInterface } from '../interfaces/appointments/appointment-add-user-data.interface';
import { AppointmentAddDataInterface } from '../interfaces/appointments/appointment-add-data.interface';
import { ClinicGetUserByPhoneInterface } from '../interfaces/clinic/schedules/clinic-get-user-by-phone.interface';
import { SwitchAppointmentInterface } from '../interfaces/appointments/switch-appointment.interface';
import { SwitchAppointmentScheduleInterface } from '../interfaces/appointments/switch-appointment-schedule.interface';
import { ScheduleBlockingFullInterface } from '../interfaces/clinic/schedules/schedule-blocking-full.interface';
import { ScheduleBlockingInterface } from '../interfaces/clinic/schedules/schedule-blocking.interface';

@Injectable()
export class ClinicService {
  clinicsListData: ClinicInterface[];
  clinicFullData: ClinicFullInterface;
  activeClinic: number;

  constructor(
    private httpService: HttpService,
    private baseService: BaseService,
    private userService: UserService,
    private dialog: MatDialog
  ) {}

  /*
   * Set List Clinics Data of Current User
   * */

  setClinicsListData(data) {
    this.clinicsListData = data as ClinicInterface[];
  }

  /*
   * Set Full Clinic Data of Current Clinic
   * */

  setClinicFullData(data) {
    this.clinicFullData = data as ClinicFullInterface;
  }

  /*
   * Get List Clinics Data of Current User
   * */

  getClinicsListData() {
    return this.clinicsListData;
  }

  /*
   * Get Full Clinic Data of Current Clinic
   * */

  getClinicFullData() {
    return this.clinicFullData;
  }

  /*
   * Register Clinic for Current User
   * */

  registerClinic(request: ClinicFullInterface) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    if (!request.UserData) {
      request.UserData = {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      };
    }

    request.AccountData = this.baseService.clearEmpty(request.AccountData);

    return this.httpService.postBaseRequest(
      request,
      api.paths.clinic.simpleClinicRegistration
    );
  }

  /*
   * Get List Clinics of Current User from Server
   * */

  findClinic() {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request: UserRequestInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        SecretKey: userIdAndSecret.SecretKey,
        UserId: userIdAndSecret.UserId,
      },
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.clinic.findClinic
    );
  }

  /*
   * Get Clinic Data of Current Clinic from Server
   * */

  currentClinic(clinicID) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request: ClinicFullInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        SecretKey: userIdAndSecret.SecretKey,
        UserId: userIdAndSecret.UserId,
      },
      AccountData: {
        AccountId: clinicID,
      },
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.clinic.currentClinic
    );
  }

  /*
   * Register Clinic for Current User
   * */

  editClinic(request: ClinicFullInterface) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    if (!request.UserData) {
      request.UserData = {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      };
    }

    request.AccountData = this.baseService.clearEmpty(request.AccountData);

    return this.httpService.postBaseRequest(
      request,
      api.paths.clinic.editAccount
    );
  }

  getAllSpecialists() {
    const request = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      Code: api.dataCodes.getAllSpecialists,
    };

    return this.httpService.postBaseRequestText(request, api.paths.data);
  }

  getClinicSpecialists(accountId) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request: GetClinicSpecialistsInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      },
      AccountData: {
        AccountId: accountId,
      },
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.clinic.getSpecialist
    );
  }

  addSpecialistToClinic(data) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    let request: AddSpecialistInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
    };

    request.UserData = {
      UserId: userIdAndSecret.UserId,
      SecretKey: userIdAndSecret.SecretKey,
    };

    request.AccountData = data.AccountData;
    request.Specialist = data.Specialist;

    return this.httpService.postBaseRequest(
      request,
      api.paths.clinic.addSpecialist
    );
  }

  /*
   * Change photo current Clinic
   * */

  changePhoto(file, accountId) {
    const request = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: this.userService.getUserIdAndSecret(),
      AccountData: {
        AccountId: accountId,
      },
      Files: [file],
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.clinic.editAccount
    );
  }

  /*
   * Open Standard Dialog
   * */

  openStandardDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null
  ) {
    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back,
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data,
    });

    return dialogRef.afterClosed();
  }

  /*
   * Register Specialist
   * */

  registerSpecialist(userData: PreClinicRegisterSpecialistInterface) {
    const request: ClinicRegisterSpecialistInterface = {
      SourceID: api.simpleRegistrationSourceId,
      SourceSecretKey: api.simpleRegistrationSourceSecretKey,
      AuthorizationType: 'Email',
      UserData: {
        Firstname: userData.Firstname,
        Surname: userData.Surname,
        City: userData.City,
        Email: userData.Email,
        TypeId: userData.TypeId,
      },
      ConcatSpecializat: userData.ContactSpecializat,
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.auth.simpleRegistration
    );
  }

  registerUser(userData: AppointmentRegisterUserDataInterface) {
    const request: AppointmentRegisterUserInterface = {
      SourceID: api.simpleRegistrationSourceId,
      SourceSecretKey: api.simpleRegistrationSourceSecretKey,
      AuthorizationType: 'Email',
      UserData: {
        Firstname: userData.Firstname,
        Surname: userData.Surname,
        Email: userData.Email,
        Phone: userData.Phone,
        TypeId: userData.TypeId,
      },
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.auth.simpleRegistration
    );
  }

  registerPet(userData: AppointmentRegisterPetDataInterface) {
    const request: AppointmentRegisterPetInterface = {
      SourceID: api.simpleRegistrationSourceId,
      SourceSecretKey: api.simpleRegistrationSourceSecretKey,
      UserData: userData,
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.pets.registrationPet
    );
  }

  getInvitedSpecialist() {
    const request: ClinicGetInvitedSpecialistInterface = {
      SourceId: api.dataSourceID,
      SourceSecretKey: api.dataSourceSecretKey,
      Code: api.dataCodes.getSpecialistsInRejectedOrExpectedStatus,
      Parameters: {
        AccountId: this.getClinicFullData().AccountData.AccountId,
      },
    };

    return this.httpService.postBaseRequestText(request, api.paths.data);
  }

  /*
   *
   * Clinic Schedules Services
   *
   * */

  /*
   * Get Schedules of specialist
   * */

  getSpecialistSchedules(specialistId) {
    const request: CurrentSpecialistScheduleInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: this.userService.getUserIdAndSecret(),
      AccountData: {
        AccountId: this.getClinicFullData().AccountData.AccountId,
      },
      SpecialistSchedule: {
        SpecialistId: specialistId,
      },
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.schedule.currentSpecialistSchedule
    );
  }

  /*
   * Add Specialist Schedule
   * */

  addClinicSpecialistSchedule(data: ClinicSpecialistScheduleInterface) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    let request: ClinicFullInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      },
      AccountData: {
        AccountId: this.getClinicFullData().AccountData.AccountId,
      },
      SpecialistSchedules: [],
    };

    request.SpecialistSchedules.push(data);

    return this.httpService.postBaseRequest(
      request,
      api.paths.schedule.addClinicSpecialistSchedule
    );
  }

  /*
   * Publication Schedule
   * */

  publicationSchedule(schedulesId: IdInterface[]) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request: ClinicFullInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      },
      AccountData: {
        AccountId: this.getClinicFullData().AccountData.AccountId,
      },
      SpecialistSchedules: schedulesId,
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.schedule.publishSpecialistSchedule
    );
  }

  getAppointments(filters: GetAppointmentsFiltersInterface) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request: ClinicFullInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      SpecialistSchedule: filters,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      },
      AccountData: {
        AccountId: this.getClinicFullData().AccountData.AccountId,
      },
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.schedule.getSpecialistScheulderWithAppointment
    );
  }

  deleteAccountDetail(data: RemoveAccountDetailInterface) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    let request: ClinicFullRemoveInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      },
      AccountData: {
        AccountId: this.getClinicFullData().AccountData.AccountId,
      },
    };

    if (data.AccClinicService) request.AccClinicService = data.AccClinicService;
    if (data.AccountMetro) request.AccountMetro = data.AccountMetro;
    if (data.AccountDirections)
      request.AccountDirections = data.AccountDirections;
    if (data.AccountInsuranceCom)
      request.AccountInsuranceCom = data.AccountInsuranceCom;
    if (data.WorkTime) request.WorkTime = data.WorkTime;

    return this.httpService.postBaseRequest(
      request,
      api.paths.clinic.deleteAccountDetail
    );
  }

  deleteSchedule(schedulesId) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request: ClinicFullInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      },
      AccountData: {
        AccountId: this.getClinicFullData().AccountData.AccountId,
      },
      SpecialistSchedule: schedulesId,
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.schedule.removeSpecialistSchedule
    );
  }

  createAppointment(
    userData: AppointmentAddUserDataInterface,
    appointmentData: AppointmentAddDataInterface
  ) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request: AppointmentAddInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      },
      AccountData: {
        AccountId: userData.AccountId,
      },
      Specialist: {
        SpecialistId: userData.SpecialistId,
      },
      Patient: {
        Id: userData.UserId,
        Pet: {
          Id: userData.PetId,
        },
      },
      Schedule: this.baseService.clearEmpty(appointmentData),
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.schedule.createAppointment
    );
  }

  getUserByPhone(userPhone) {
    const request: ClinicGetUserByPhoneInterface = {
      SourceId: api.dataSourceID,
      SourceSecretKey: api.dataSourceSecretKey,
      Code: api.dataCodes.getPetsByName,
      Parameters: {
        Phone: userPhone,
      },
    };

    return this.httpService.postBaseRequestText(request, api.paths.data);
  }

  switchAppointment(schedule: SwitchAppointmentScheduleInterface[]) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request: SwitchAppointmentInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      },
      Schedules: schedule,
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.schedule.switchAppointment
    );
  }

  createBlockAppointment(
    accountId: string,
    blockData: ScheduleBlockingInterface
  ) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request: ScheduleBlockingFullInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      },
      AccountData: {
        AccountId: accountId,
      },
      ScheduleBlocking: blockData,
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.schedule.createScheduleBlocking
    );
  }

  removeBlockAppointment(
    accountId: string,
    blockData: ScheduleBlockingInterface
  ) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request: ScheduleBlockingFullInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      },
      AccountData: {
        AccountId: accountId,
      },
      ScheduleBlocking: blockData,
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.schedule.removeScheduleBlocking
    );
  }

  editMedicines(medicines) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request: ClinicFullInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      },
      AccountData: {
        AccountId: this.clinicFullData.AccountData.AccountId,
        ClinicMedicines: medicines,
      },
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.clinic.editAccount
    );
  }
}
