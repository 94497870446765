import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './services/http.service';
import { BaseService } from './services/base.service';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth.guard';
import { DadataService } from './services/dadata.service';
import { DadataComponent } from './components/dadata/dadata.component';
import { CommonModule } from '@angular/common';
import { CustomValidators } from './validators/custom.validators';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AccountSidebarComponent } from './components/account-sidebar/account-sidebar.component';
import { RouterModule } from '@angular/router';
import { AccountChangePhotoComponent } from './components/account-change-photo/account-change-photo.component';
import { ngfModule } from 'angular-file';
import { ImageCropperModule } from './plugins/cropper';
import {
  MatAutocompleteModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatSnackBarModule,
} from '@angular/material';
import { DadataMatComponent } from './components/dadata-mat/dadata-mat.component';
import { NavToggleDirective } from './directives/nav-toggle.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { StatusDialogComponent } from './components/status-dialog/status-dialog.component';
import { SubmitDialogComponent } from './components/submit-dialog/submit-dialog.component';
import { DialogService } from './services/dialog.service';
import { ClinicService } from './services/clinic.service';
import { SidebarClinicComponent } from './components/sidebar-clinic/sidebar-clinic.component';
import { DateChangerComponent } from './components/date-changer/date-changer.component';
import { AppointmentGridComponent } from './components/appointments/appointment-grid/appointment-grid.component';
import { AppointmentAddTimegridDialogComponent } from './components/appointments/appointment-add-timegrid-dialog/appointment-add-timegrid-dialog.component';
import { CreateAppointmentDialogComponent } from './components/appointments/create-appointment-dialog/create-appointment-dialog.component';
import { CreateAppointmentNewUserComponent } from './components/appointments/create-appointment-new-user/create-appointment-new-user.component';
import { CreateAppointmentSearchUserComponent } from './components/appointments/create-appointment-search-user/create-appointment-search-user.component';
import { TextMaskModule } from 'angular2-text-mask';
import { CreateAppointmentMoveComponent } from './components/appointments/create-appointment-move/create-appointment-move.component';
import { PipesModule } from './pipes/pipes.module';
import { ForceAutocompleteComponent } from './components/force-autocomplete/force-autocomplete.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    ngfModule,
    ImageCropperModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatSelectModule,
    TextMaskModule,
    PipesModule,
  ],
  exports: [
    DadataComponent,
    HeaderComponent,
    SidebarComponent,
    AccountSidebarComponent,
    DadataMatComponent,
    DisableControlDirective,
    PreloaderComponent,
    SidebarClinicComponent,
    DateChangerComponent,
    AppointmentGridComponent,
    AppointmentAddTimegridDialogComponent,
    CreateAppointmentDialogComponent,
    CreateAppointmentNewUserComponent,
    CreateAppointmentSearchUserComponent,
    ForceAutocompleteComponent,
  ],
  declarations: [
    DadataComponent,
    DadataMatComponent,
    HeaderComponent,
    SidebarComponent,
    AccountSidebarComponent,
    AccountChangePhotoComponent,
    NavToggleDirective,
    DisableControlDirective,
    PreloaderComponent,
    StatusDialogComponent,
    SubmitDialogComponent,
    SidebarClinicComponent,
    DateChangerComponent,
    AppointmentGridComponent,
    AppointmentAddTimegridDialogComponent,
    CreateAppointmentDialogComponent,
    CreateAppointmentNewUserComponent,
    CreateAppointmentSearchUserComponent,
    CreateAppointmentMoveComponent,
    ForceAutocompleteComponent,
  ],
  entryComponents: [
    AccountChangePhotoComponent,
    StatusDialogComponent,
    SubmitDialogComponent,
    AppointmentAddTimegridDialogComponent,
    CreateAppointmentDialogComponent,
  ],
  providers: [
    BaseService,
    HttpService,
    AuthService,
    AuthGuard,
    UserService,
    DadataService,
    CustomValidators,
    DialogService,
    ClinicService,
  ],
})
export class SharedModule {}
