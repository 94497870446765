import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[appNavToggle]',
})
export class NavToggleDirective {
  @HostBinding('class.active') isOpen = false;

  @HostListener('click') toggleOpen($event) {
    this.isOpen = !this.isOpen;
  }
}
