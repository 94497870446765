import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { api } from '../../../environments/api';
import { Subscription } from 'rxjs';
import { NeedConfirmResponseInterface } from '../../shared/interfaces/need-confirm-response.interface';
import { SendConfirmResponseInterface } from '../../shared/interfaces/send-confirm-response.interface';
import { UserInterface } from '../../shared/interfaces/user/user.interface';
import { MatDialog } from '@angular/material';
import { DialogInterface } from '../../shared/interfaces/dialog.interface';
import { AccountDialog } from '../account.component';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
})
export class AccountInfoComponent implements OnInit {
  userCurrentData: UserInterface;
  showConfirmationEmail: boolean = false;

  timeout: boolean = true;
  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;
  preloaderVisibility: boolean = true;

  phone: string;

  sendCodeSubscribe: Subscription;

  constructor(private userService: UserService, private dialog: MatDialog) {}

  ngOnInit() {
    const userDataLink = this.userService.getUserData().UserData;

    let data: UserInterface = {};

    for (let key in userDataLink) {
      data[key] = userDataLink[key];

      if (key != 'Phone') {
        !data[key] ? (data[key] = 'Не указано') : null;
      } else {
        data[key] ? (data[key] = data[key].slice(1)) : null;
      }
    }

    this.userCurrentData = data;

    this.userService
      .needConfirmation('Email')
      .subscribe((response: NeedConfirmResponseInterface) => {
        response.NeedCommunicationConfirmationResult.Success
          ? (this.showConfirmationEmail = true)
          : (this.showConfirmationEmail = false);
      });
  }

  sendConfirmationRequest() {
    this.preloaderVisibility = true;
    this.timeout = true;

    this.userService
      .sendConfirmationCode('Email')
      .subscribe((response: SendConfirmResponseInterface) => {
        this.timeout = false;
        this.preloaderVisibility = false;

        if (response.SendConfirmationCodeResult.Success) {
          this.openDialog(
            'Письмо отправлено!',
            'Вам на почту отправленна ссылка для подтверждения регистрации <br> Пожалуйста, перейдите по ней, чтобы пользоваться всеми возможностями нашего сервиса. Спасибо!'
          );
        } else {
          this.openDialog(
            'Ошибка отправки письма!',
            'Во время отправки письма для подтверждения почты возникла ошибка <br> Пожалуйста, попробуйте позже или обратитесь в службу поддержки.',
            true
          );
        }
      });

    setTimeout(() => {
      if (this.timeout) {
        this.preloaderVisibility = false;
        this.openDialog(
          'Ошибка отправки письма!',
          'Во время отправки письма для подтверждения почты возникла ошибка <br> Пожалуйста, попробуйте позже или обратитесь в службу поддержки.',
          true
        );
        this.sendCodeSubscribe.unsubscribe();
      }
    }, 30000);
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null
  ) {
    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back,
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data,
    });
  }
}
