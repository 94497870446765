import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class CustomValidators {
  public passwordMatchValidator(control: FormControl): ValidationErrors {
    return control.get('password').value ===
      control.get('passwordConfirm').value
      ? null
      : { mismatch: 'Пароли не совпадают' };
  }

  public email(control: FormControl): ValidationErrors {
    const regexp = /^[a-z0-9._%+-]+@[a-z0-9-]+\.[a-z]{2,10}$/;

    return regexp.test(control.value)
      ? null
      : { email: 'Не верно указан E-mail' };
  }

  public phone(control: FormControl): ValidationErrors {
    const regexp = /\+\d\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/g;

    if (!control.value) {
      return null;
    }

    return regexp.test(control.value)
      ? null
      : { phone: 'Не верный формат телефона' };
  }

  public minTimeSchedule(control: FormControl): ValidationErrors {
    if (
      control.parent &&
      control.parent.value.startTime &&
      control.parent.value.dueTime
    ) {
      const startTime = moment('2000-01-01 ' + control.parent.value.startTime);
      const dueTime = moment('2000-01-01 ' + control.parent.value.dueTime);

      if (dueTime < startTime) {
        return {
          minTimeSchedule:
            'Время окончания не может быть меньше времени начала',
        };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public autofillForceRequire(control: FormControl): ValidationErrors {
    const selection: any = control.value;
    if (typeof selection === 'string') {
      return { incorrect: true };
    }
    return null;
  }
}
