import { Injectable } from '@angular/core';
import { DialogInterface } from '../interfaces/dialog.interface';
import { MatDialog } from '@angular/material';
import { StatusDialogComponent } from '../components/status-dialog/status-dialog.component';
import { SubmitDialogComponent } from '../components/submit-dialog/submit-dialog.component';
import { api } from '../../../environments/api';
import { Subscription } from 'rxjs/Subscription';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;

  constructor(private dialog: MatDialog) {}

  /*
   * Открытие диалога со статусом "успех" или "провал"
   * */

  openStatusDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null
  ) {
    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back,
    };

    const dialogRef = this.dialog.open(StatusDialogComponent, {
      data: data,
    });

    return dialogRef.afterClosed();
  }

  /*
   * Вызов модального окна со стандартной ошибкой
   * */

  showStandardError() {
    this.openStatusDialog(this.errorTitle, this.errorMessage, true);
  }

  /*
   * Открытие диалога с подтверждением "да" или "нет"
   * */

  openSubmitDialog(title: string, message: string) {
    const data: DialogInterface = {
      title: title,
      message: message,
    };

    const dialogRef = this.dialog.open(SubmitDialogComponent, {
      data: data,
    });

    return dialogRef.afterClosed();
  }
}
