import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

export const timepickerTheme: NgxMaterialTimepickerTheme = {
  container: {
    bodyBackgroundColor: '#fff',
    buttonColor: '#ea6728',
  },
  dial: {
    dialBackgroundColor: '#ea6728',
  },
  clockFace: {
    clockFaceBackgroundColor: '#f0f0f0',
    clockHandColor: '#ea6728',
    clockFaceTimeInactiveColor: '#404040',
  },
};
