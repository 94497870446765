import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { DaDataResponse } from '../../models/dadata/da-data-response';
import { DaDataSuggestion } from '../../models/dadata/suggestion';
import { DaDataConfig, DaDataConfigDefault } from '../../da-data-config';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { DadataService, DaDataType } from '../../services/dadata.service';
import { BaseService } from '../../services/base.service';

const NGX_DADATA_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DadataMatComponent),
  multi: true,
};

@Component({
  selector: 'dadata-mat',
  templateUrl: './dadata-mat.component.html',
  styleUrls: ['./dadata-mat.component.scss'],
  providers: [NGX_DADATA_VALUE_ACCESSOR],
})
export class DadataMatComponent
  implements OnInit, ControlValueAccessor, OnChanges {
  private _value: any = '';
  currentFocus = -1;
  additional;

  data: DaDataSuggestion[] = [];

  @Input() config: DaDataConfig = DaDataConfigDefault;
  @Input() apiKey: string;
  @Input() disabled = null;
  @Input() type = DaDataType.address;
  @Input() limit = DaDataConfigDefault.limit;
  @Input() placeholder = '';
  @Input() fromBound = '';
  @Input() toBound = '';
  @Input() restrict: Boolean = false;
  @Input() locations: any[] = [];
  @Input() parts: string[] = [''];
  @Input() cityFiasCheck: boolean = false;
  @Input() required: boolean = false;
  @Input() control: FormControl = new FormControl();

  @Output() selectedSuggestion: DaDataSuggestion;
  @Output() selected = new EventEmitter<DaDataSuggestion>();

  @Output('suggestion') suggestionEvent = new EventEmitter();

  @ViewChild('inputValue') inputValue: ElementRef;

  private inputString$ = new Subject<string>();

  onTouched = () => {};
  propagateChange: any = () => {};

  constructor(
    private dataService: DadataService,
    private baseService: BaseService
  ) {}

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.propagateChange(v);
    }
  }

  ngOnInit() {
    this.dataService.setApiKey(this.apiKey ? this.apiKey : this.config.apiKey);

    this.inputString$
      .pipe(debounce(() => timer(this.config.delay ? this.config.delay : 500)))
      .subscribe((x) => {
        this.additional = {
          parts: this.parts,
          fromBound: this.fromBound,
          toBound: this.toBound,
          locations: this.locations,
          restrict: this.restrict,
        };

        this.dataService
          .getData(x, this.type, this.limit, this.additional)
          .subscribe((y: DaDataResponse) => {
            this.data = y.suggestions;
          });
      });
  }

  setCityFias(selectVal) {
    // this.selected.emit(selectVal.option.value);

    if (this.cityFiasCheck) {
      this.baseService
        .postSuggestion(selectVal.option.value)
        .subscribe((response: any) => {
          if (response.suggestions.length) {
            this.selected.emit(response.suggestions[0].data.city_fias_id);
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      this.suggestionEvent.emit(changes.value);
    }
  }

  onInputBlur() {
    !this.disabled ? this.onTouched() : null;
  }

  getData(value: string) {
    this.inputString$.next(value);
    this.currentFocus = -1;
  }

  @HostListener('document:click')
  onOutsideClick() {
    this.data = [];
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this._value = value;
    }
  }

  /**
   * Set the function to be called
   * when the control receives a change event.
   *
   * @param fn a function
   */
  registerOnChange(fn: any): void {
    // this.onSuggestionSelected = fn;
    this.propagateChange = fn;
  }

  /**
   * Set the function to be called
   * when the control receives a touch event.
   *
   * @param fn a function
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Implements disabled state for this element
   *
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
