import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { api } from '../../../../environments/api';
import { ClinicService } from '../../../shared/services/clinic.service';
import { AllSpecialistsListInterface } from '../../../shared/interfaces/all-specialists-list.interface';
import { DialogInterface } from '../../../shared/interfaces/dialog.interface';
import { AccountDialog } from '../../../account/account.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AddSpecialistInterface } from '../../../shared/interfaces/clinic/add-specialist.interface';
import { AddSpecialistResponseInterface } from '../../../shared/interfaces/clinic/add-specialist-response.interface';

@Component({
  selector: 'app-specialists-add',
  templateUrl: './specialists-add.component.html',
  styleUrls: ['./specialists-add.component.scss'],
})
export class SpecialistsAddComponent implements OnInit {
  preloaderVisibility: boolean = true;

  getAllSpecialistsSubscribe: Subscription;
  addSpecialist: Subscription;
  timeout = true;
  timeout2 = true;
  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;

  searchText: string;
  exclude: string[] = ['SpecializationId', 'UserId'];

  specialistsList: AllSpecialistsListInterface[] = [];

  constructor(
    public clinicService: ClinicService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.getAllSpecialists();
  }

  getAllSpecialists() {
    this.getAllSpecialistsSubscribe = this.clinicService
      .getAllSpecialists()
      .subscribe(
        (response: AllSpecialistsListInterface[]) => {
          this.specialistsList = response;
          this.preloaderVisibility = false;
          this.timeout = false;
          console.log('AllSpecialistsList', response);
        },
        () => {
          this.timeout = false;
          this.openDialog(
            this.errorTitle,
            this.errorMessage,
            true,
            'back-list'
          );
        }
      );

    setTimeout(() => {
      if (this.timeout) {
        this.openDialog(this.errorTitle, this.errorMessage, true);
        this.getAllSpecialistsSubscribe.unsubscribe();
      }
    }, 30000);
  }

  addSpecialistToClinic(id) {
    console.log('index', id);

    const request: AddSpecialistInterface = {
      AccountData: {
        AccountId: this.clinicService.getClinicFullData().AccountData.AccountId,
      },
      Specialist: {
        SpecialistId: id,
      },
    };

    this.addSpecialist = this.clinicService
      .addSpecialistToClinic(request)
      .subscribe(
        (response: AddSpecialistResponseInterface) => {
          this.timeout2 = false;

          console.log('AddSpecialistResponse', response);

          if (response.AddSpecialistResult.Success) {
            this.openDialog(
              'Вы добавили специалиста!',
              'Вы успешно добавили специалиста в вашу клинику'
            );
          } else {
            this.openDialog(this.errorTitle, this.errorMessage, true);
          }
        },
        () => {
          this.timeout2 = false;
          this.openDialog(this.errorTitle, this.errorMessage, true);
        }
      );

    setTimeout(() => {
      if (this.timeout2) {
        this.openDialog(this.errorTitle, this.errorMessage, true);

        this.getAllSpecialistsSubscribe.unsubscribe();
      }
    }, 30000);
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null
  ) {
    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back,
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.preloaderVisibility = false;

      result == 'back-list'
        ? this.router.navigate([
            '/clinic',
            this.clinicService.activeClinic,
            'specialists',
            'list',
          ])
        : null;
    });
  }
}
