import * as moment from 'moment';
import { addZero, dateEncodeNoUtc, getUnixDate } from './functions';
import { AppointmentsGridInterface } from '../interfaces/appointments/appointments-grid.interface';
import { AppointmentsAdminTimeGridInterface } from '../interfaces/appointments/appointments-admin-time-grid.interface';
import { ScheduleAdminActivitiesInterface } from '../interfaces/clinic/schedules/schedule-admin-activities.interface';

export function setTimeZeroFormatUtc(date) {
  return date.hours(0).minutes(0).milliseconds(0).utc().valueOf();
}

export function createFullName(name) {
  const specialistNameSplit = name.split(' ');
  const specialistSurname = specialistNameSplit[0]
    ? specialistNameSplit[0]
    : null;
  const specialistName = specialistNameSplit[1]
    ? specialistNameSplit[1].substring(0, 1)
    : null;
  const specialistMiddleName = specialistNameSplit[2]
    ? specialistNameSplit[2].substring(0, 1)
    : null;

  return (
    specialistSurname + ' ' + specialistName + '. ' + specialistMiddleName + '.'
  );
}

export function splitTimeSells(item, date) {
  const startDate = moment(dateEncodeNoUtc(item.StartDate));
  let array = [];

  let isEnable = item.IsEnable;

  const specialistFullName = createFullName(item.SpecialistName);

  if (isEnable) {
    isEnable = startDate.valueOf() > getUnixDate(true);
  }

  array.push({
    Date: date,
    Time: startDate,
    StartDate: item.StartDate,
    DueDate: item.DueDate,
    IsEnable: isEnable,
    TimeString:
      addZero(startDate.get('hours')) + ':' + addZero(startDate.get('minutes')),
    Price: item.ScheduleReceptionTypes[0].Price,
    PlaceAppointmentId: item.ScheduleReceptionTypes[0].TypeId,
    // Duration: item.ScheduleReceptionTypes[0].Duration,
    SpecialistId: item.SpecialistId,
    SpecialistName: specialistFullName,
    AccountId: item.AccountId,
    AccountName: item.AccountName,
    SpecializationId: item.SpecializationId,
    SpecializationName: item.SpecializationName,
  });

  return array;
}

export function createAppointmentsGrid(
  appointments: AppointmentsGridInterface,
  item,
  dateRange
) {
  const key = item.SpecialistId + '-' + item.SpecializationId;

  if (!appointments.hasOwnProperty(key)) {
    const specialistFullName = createFullName(item.SpecialistName);

    appointments[key] = {
      SpecialistId: item.SpecialistId,
      SpecialistName: specialistFullName,
      SpecializationName: item.SpecializationName,
      WorkDates: {},
    };
  }

  if (Object.keys(appointments[key].WorkDates).length == 0) {
    dateRange.forEach((date) => {
      appointments[key].WorkDates[setTimeZeroFormatUtc(moment(date))] = {};
    });
  }

  const date = setTimeZeroFormatUtc(moment(dateEncodeNoUtc(item.StartDate)));

  if (
    appointments[key].WorkDates.hasOwnProperty(date) &&
    Object.keys(appointments[key].WorkDates[date]).length == 0
  ) {
    appointments[key].WorkDates[date] = {
      Date: date,
      Length: 0,
      Appointments: [],
    };
  }

  let currentAppointments = appointments[key].WorkDates[date].Appointments;

  appointments[key].WorkDates[date].Appointments = currentAppointments.concat(
    splitTimeSells(item, date)
  );

  appointments[key].WorkDates[date].Length = appointments[key].WorkDates[
    date
  ].Appointments.filter((x) => x.IsEnable === true).length;
}

export function adminSplitTimeSells(
  item: ScheduleAdminActivitiesInterface,
  date
) {
  const startDate = moment(dateEncodeNoUtc(item.StartDate));
  let array = [];

  const specialistFullName = createFullName(item.SpecialistName);

  let data: AppointmentsAdminTimeGridInterface = {
    Date: date,
    Time: startDate,
    StartDate: item.StartDate,
    DueDate: item.DueDate,
    IsEnable: item.IsEnable,
    IsBlocked: false,
    BlockId: item.ScheduleBlockingId,
    TimeString:
      addZero(startDate.get('hours')) + ':' + addZero(startDate.get('minutes')),
    Price: item.ScheduleReceptionTypes[0].Price,
    TypeId: item.ScheduleReceptionTypes[0].TypeId,
    // Duration: item.ScheduleReceptionTypes[0].Duration,
    SpecialistId: item.SpecialistId,
    SpecialistName: specialistFullName,
    AccountId: item.AccountId,
    AccountName: item.AccountName,
    SpecializationId: item.SpecializationId,
    SpecializationName: item.SpecializationName,
    ClinicService: item.ClinicService,
    Address: item.Address,
    Compilant: item.Compilant,
    SpecialistScheduleId: item.SpecialistScheduleId,
  };

  if (item.ScheduleBlockingId) {
    data.IsBlocked = true;
  }

  if (item.SchedulerAccountClients) {
    data.SchedulerAccountClients = item.SchedulerAccountClients;
  }

  array.push(data);

  return array;
}

export function createAdminAppointmentsGrid(
  appointments: AppointmentsAdminTimeGridInterface,
  item: ScheduleAdminActivitiesInterface,
  dateRange
) {
  const key = item.SpecialistId + '-' + item.SpecializationId;

  if (!appointments.hasOwnProperty(key)) {
    const specialistFullName = createFullName(item.SpecialistName);

    appointments[key] = {
      SpecialistId: item.SpecialistId,
      SpecialistName: specialistFullName,
      SpecializationName: item.SpecializationName,
      WorkDates: {},
    };
  }

  if (Object.keys(appointments[key].WorkDates).length == 0) {
    dateRange.forEach((date) => {
      appointments[key].WorkDates[setTimeZeroFormatUtc(moment(date))] = {};
    });
  }

  const date = setTimeZeroFormatUtc(moment(dateEncodeNoUtc(item.StartDate)));

  if (
    appointments[key].WorkDates.hasOwnProperty(date) &&
    Object.keys(appointments[key].WorkDates[date]).length == 0
  ) {
    appointments[key].WorkDates[date] = {
      Date: date,
      Length: 0,
      Appointments: [],
    };
  }

  let currentAppointments = appointments[key].WorkDates[date].Appointments;

  appointments[key].WorkDates[date].Appointments = currentAppointments.concat(
    adminSplitTimeSells(item, date)
  );

  appointments[key].WorkDates[date].Length =
    appointments[key].WorkDates[date].Appointments.length;
}
