import { DaDataType } from './services/dadata.service';
import { api } from '../../environments/api';

export interface DaDataConfig {
  apiKey: string;
  type?: DaDataType;
  delay?: number;
  limit?: number;
  width?: 'auto' | string;
  minWidth?: '0' | string;
}

export const DaDataConfigDefault: DaDataConfig = {
  apiKey: api.dadataApi,
  type: DaDataType.address,
  delay: 500,
  limit: 10,
  width: 'auto',
  minWidth: '0',
};
