import { Injectable } from '@angular/core';
import { api } from '../../../environments/api';
import { HttpService } from './http.service';
import { BaseService } from './base.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { LoginInterface } from '../interfaces/auth/login.interface';
import { LoginDataInterface } from '../interfaces/auth/login-data.interface';
import { UserRequestInterface } from '../interfaces/user/user-request.interface';
import { RegisterInterface } from '../interfaces/auth/register.interface';
import { RestorePasswordInterface } from '../interfaces/auth/restore-password.interface';
import { AddUserEventInterface } from '../interfaces/auth/addUserEventInterface';

@Injectable()
export class AuthService {
  authorization: Boolean = false;

  constructor(
    private baseService: BaseService,
    private httpService: HttpService,
    private userService: UserService,
    private router: Router
  ) {}

  /*
   * Request for registration User
   * */

  setAuthorization() {
    this.authorization = true;
  }

  /*
   * Request for registration User
   * */

  unsetAuthorization() {
    this.authorization = false;
  }

  /*
   * Request for registration User
   * */

  getAuthorization() {
    return this.authorization;
  }

  /*
   * Request for registration User
   * */

  signUp(userData) {
    const request: RegisterInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: 'Email',
      UserData: userData,
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.auth.registration
    );
  }

  simpleRegistration(userData) {
    const request: RegisterInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: 'Email',
      UserData: userData,
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.auth.simpleRegistration
    );
  }

  /*
   * Request for authorization User
   * */

  login(userData: LoginDataInterface) {
    const request: LoginInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: 'Email',
      UserData: userData,
    };

    return this.httpService.postBaseRequest(request, api.paths.auth.login);
  }

  /*
   * Request for authorization with social User
   * */

  socialLogin(userData, type) {
    const request: LoginInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: type,
      UserData: userData,
    };

    return this.httpService.postBaseRequest(request, api.paths.auth.login);
  }

  /*
   * Request for reset Password User
   * */

  recover(userId) {
    const request: AddUserEventInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userId,
      },
      Campaign: {
        Id: '04336eb0-9e99-492d-9c01-a6a9fd407c0a',
        Event: {
          Id: 'c34b3f20-7fd8-435d-94cc-46b8133cc864',
        },
      },
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.user.addUserEvent
    );
  }

  /*
   * Simple Login Hash
   * */

  simpleLoginHash(hash) {
    const request: LoginInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: 'Hash',
      UserData: {
        Hash: hash,
      },
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.auth.simpleLogin
    );
  }

  /*
   * Update User Hash
   * */

  updateUserHash(data) {
    const request: LoginInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: 'Hash',
      UserData: {
        UserId: data.userId,
        SecretKey: data.secretKey,
      },
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.user.updateSiteUserHash
    );
  }

  /*
   * Save new password
   * */

  restorePassword(userData) {
    const request: RestorePasswordInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: userData,
    };

    return this.httpService.postBaseRequest(request, api.paths.auth.restore);
  }

  /*
   * Request for logout User
   * */

  logOut(redirect: boolean = true) {
    const request: UserRequestInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: this.userService.getUserIdAndSecret(),
    };

    this.userService.clearLocalData();

    if (redirect) {
      this.router.navigate(['/auth/sign-in']);
    }

    return this.httpService.postBaseRequest(request, api.paths.auth.logout);
  }

  /*
   * Validate Current Session of User
   * */

  sessionValidate() {
    const userData = this.userService.getUserIdAndSecret();

    !userData.UserId
      ? (userData.UserId = '393101e7-694d-4e0c-8719-0000d4f2c51d')
      : null;
    !userData.SecretKey ? (userData.SecretKey = 'null') : null;

    const request: any = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: userData,
    };

    return this.httpService.postBaseRequest(request, api.paths.auth.isValid);
  }

  /*
   * Confirmation account registration
   * */

  confirmRegistration(hash: string) {
    let secretKey = this.userService.getUserIdAndSecret().SecretKey;
    let userId;

    !this.userService.getUserIdAndSecret().UserId
      ? (userId = '393101e7-694d-4e0c-8719-0000d4f2c51d')
      : (userId = this.userService.getUserIdAndSecret().UserId);

    !secretKey ? (secretKey = 'none') : null;

    const request: LoginInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AuthorizationType: 'Email',
      UserData: {
        Hash: hash,
        UserId: userId,
        SecretKey: secretKey,
      },
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.user.confirmCommunicationByCode
    );
  }
}
