import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { AppointmentsAdminTimeGridInterface } from '../../../../shared/interfaces/appointments/appointments-admin-time-grid.interface';
import { ScheduleAccountClientInterface } from '../../../../shared/interfaces/clinic/schedules/schedule-account-client.interface';
import { ScheduleAccountClientPetInterface } from '../../../../shared/interfaces/clinic/schedules/schedule-account-client-pet.interface';
import { SelectInterface } from '../../../../shared/interfaces/select.interface';
import { clinicServices } from '../../../../shared/catalogs/clinic.catalogs';
import { ClinicService } from '../../../../shared/services/clinic.service';
import { timeout } from 'rxjs/operators';
import { api } from '../../../../../environments/api';
import { SwitchAppointmentScheduleInterface } from '../../../../shared/interfaces/appointments/switch-appointment-schedule.interface';
import { SwitchAppointmentResponseInterface } from '../../../../shared/interfaces/appointments/switch-appointment-response.interface';
import { DialogService } from '../../../../shared/services/dialog.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ScheduleBlockingInterface } from '../../../../shared/interfaces/clinic/schedules/schedule-blocking.interface';
import { ScheduleBlockingResponseInterface } from '../../../../shared/interfaces/clinic/schedules/schedule-blocking-response.interface';
import { FormControl } from '@angular/forms';
import { ScheduleBlockingRemoveResponseInterface } from '../../../../shared/interfaces/clinic/schedules/schedule-blocking-remove-response.interface';
import { Router } from '@angular/router';
import { AppointmentsMoveQueryParamsInterface } from '../../../../shared/interfaces/appointments/appointments-move-query-params.interface';

@Component({
  selector: 'app-appointment-edit-dialog',
  templateUrl: './appointment-edit-dialog.component.html',
  styleUrls: ['./appointment-edit-dialog.component.scss'],
})
export class AppointmentEditDialogComponent implements OnInit, OnDestroy {
  userImage: string;
  petImage: string;
  item: AppointmentsAdminTimeGridInterface = this.dialogData.item;
  key = this.dialogData.key;
  client: ScheduleAccountClientInterface;
  pet: ScheduleAccountClientPetInterface;
  isAppointment = false;
  isBlocked = false;
  selectClinicServices: SelectInterface[] = clinicServices;
  actionBlock: FormControl = new FormControl(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AppointmentEditDialogComponent>,
    private clinicService: ClinicService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit() {
    if (
      this.item.SchedulerAccountClients &&
      this.item.SchedulerAccountClients.length
    ) {
      this.isAppointment = true;
      this.client = this.item.SchedulerAccountClients[0];
      this.pet = this.client.Pets[0];

      this.initAvatars();
    }

    if (this.item.IsBlocked) {
      this.isBlocked = true;
    }
  }

  initAvatars() {
    if (this.client.File) {
      let type: any = this.client.File.Name.split('.');
      type = type[type.length - 1];
      this.userImage =
        'data:image/' + type + ';base64,' + this.client.File.Content;
    }

    if (this.pet.File) {
      let type: any = this.pet.File.Name.split('.');
      type = type[type.length - 1];
      this.petImage = 'data:image/' + type + ';base64,' + this.pet.File.Content;
    }
  }

  cancelAppointment(move = false) {
    const afterBlock = this.actionBlock.value;
    let type = 1;

    if (move) {
      type = 2;
    }

    const schedule: SwitchAppointmentScheduleInterface[] = [];

    schedule.push({
      Id: this.client.ActivityId,
      StatusId: api.switchAppointmentList.canceled,
    });

    this.clinicService
      .switchAppointment(schedule)
      .pipe(timeout(api.requestTimeout), untilDestroyed(this))
      .subscribe((response: SwitchAppointmentResponseInterface) => {
        !api.production
          ? console.log('SwitchAppointmentResponseInterface', response)
          : null;

        if (response.SwitchAppointmentResult.Success) {
          if (afterBlock) {
            this.blockAppointment(type);
          } else if (move) {
            this.dialogService
              .openStatusDialog(
                'Вы успешно отмеили прием.',
                'Прием был успешно отменен. После подтверждения вы будете перенаправлены на создание нового приема.'
              )
              .subscribe(() => {
                this.router.navigate(
                  [
                    '/clinic',
                    this.clinicService.activeClinic,
                    'schedule',
                    'appointment-add',
                  ],
                  {
                    queryParams: this.createQueryParams(),
                  }
                );
                this.dialog.closeAll();
              });
          } else {
            this.dialogService
              .openStatusDialog(
                'Вы успешно отменили прием',
                'Прием был успешно отменен'
              )
              .pipe(untilDestroyed(this))
              .subscribe(() => {
                this.closeDialog({
                  type: 'removeAppointment',
                });
              });
          }
        } else {
          this.dialogService.showStandardError();
        }
      });
  }

  blockAppointment(type) {
    const accountId = this.clinicService.getClinicFullData().AccountData
      .AccountId;

    const blockData: ScheduleBlockingInterface = {
      StartDate: this.item.StartDate,
      EndDate: this.item.DueDate,
      SpecialistId: this.item.SpecialistId,
      AccountId: accountId,
    };

    this.clinicService
      .createBlockAppointment(accountId, blockData)
      .pipe(timeout(api.requestTimeout), untilDestroyed(this))
      .subscribe((response: ScheduleBlockingResponseInterface) => {
        !api.production
          ? console.log('ScheduleBlockingResponseInterface', response)
          : null;

        if (response.CreateScheduleBlockingResult.Success) {
          switch (type) {
            case 1:
              this.dialogService
                .openStatusDialog(
                  'Вы успешно отмеили запись.',
                  'Прием был успешно отменен после чего заблокирован, теперь на это время нельзя записаться.'
                )
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                  this.closeDialog({
                    type: 'removeAppointmentBlock',
                    blockId:
                      response.CreateScheduleBlockingResult.ScheduleBlocking.Id,
                  });
                });
              break;
            case 2:
              this.dialogService
                .openStatusDialog(
                  'Вы успешно отмеили прием и заблокировали запись.',
                  'Прием был успешно отменен после чего заблокирован, теперь на это время нельзя записаться. После подтверждения вы будете перенаправлены на создание нового приема.'
                )
                .subscribe(() => {
                  this.router.navigate(
                    [
                      '/clinic',
                      this.clinicService.activeClinic,
                      'schedule',
                      'appointment-add',
                    ],
                    {
                      queryParams: this.createQueryParams(),
                    }
                  );
                  this.dialog.closeAll();
                });
              break;
            default:
              this.dialogService
                .openStatusDialog(
                  'Вы успешно заблокировали прием',
                  'Прием был успешно заблокирован, теперь на это время нельзя записаться.'
                )
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                  this.closeDialog({
                    type: 'block',
                    blockId:
                      response.CreateScheduleBlockingResult.ScheduleBlocking.Id,
                  });
                });
              break;
          }
        } else {
          this.dialogService.showStandardError();
        }
      });
  }

  removeBlockAppointment() {
    const accountId = this.clinicService.getClinicFullData().AccountData
      .AccountId;

    const blockData: ScheduleBlockingInterface = {
      SpecialistId: this.item.SpecialistId,
      Id: this.item.BlockId,
    };

    this.clinicService
      .removeBlockAppointment(accountId, blockData)
      .pipe(timeout(api.requestTimeout), untilDestroyed(this))
      .subscribe((response: ScheduleBlockingRemoveResponseInterface) => {
        !api.production
          ? console.log('ScheduleBlockingRemoveResponseInterface', response)
          : null;

        if (response.RemoveScheduleBlockingResult.Success) {
          this.dialogService
            .openStatusDialog(
              'Вы успешно сняли блокировку.',
              'Блокировка была снята, теперь на это время можно записаться.'
            )
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              this.closeDialog({
                type: 'unblock',
              });
            });
        } else {
          this.dialogService.showStandardError();
        }
      });
  }

  createQueryParams() {
    return <AppointmentsMoveQueryParamsInterface>{
      MoveAppointment: true,
      TypeId: this.item.ClinicService.TypeId,
      PlaceAppointmentId: this.item.TypeId,
      StartDate: this.item.StartDate,
      DueDate: this.item.DueDate,
      Compliant: this.item.Compilant,
      Name: this.client.Name,
      Phone: this.client.Phone,
      Email: this.client.Email,
      PetName: this.pet.Name,
      Price: 0,
      Address: this.item.Address,
      ServiceId: this.item.ClinicService.Id,
      UserId: this.client.Id,
      PetId: this.pet.Id,
    };
  }

  closeDialog(type?) {
    if (!type) {
      type = {
        type: 'none',
      };
    }
    console.log('close', type);
    this.dialogRef.close(type);
  }

  ngOnDestroy() {}
}
