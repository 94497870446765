export function downscaleImage(dataUrl, newWidth, imageType?, imageArguments?) {
  let image, oldWidth, oldHeight, newHeight, canvas, ctx, newDataUrl;

  // Provide default values
  imageType = imageType || 'image/jpeg';
  imageArguments = imageArguments || 0.8;

  // Create a temporary image so that we can compute the height of the downscaled image.
  image = new Image();
  image.src = dataUrl;
  oldWidth = image.width;
  oldHeight = image.height;
  newHeight = Math.floor((oldHeight / oldWidth) * newWidth);

  // Create a temporary canvas to draw the downscaled image on.
  canvas = document.createElement('canvas');
  canvas.width = newWidth;
  canvas.height = newHeight;

  // Draw the downscaled image on the canvas and return the new data URL.
  ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, newWidth, newHeight);
  newDataUrl = canvas.toDataURL(imageType, imageArguments);
  return newDataUrl;
}

export function replaceDate(date) {
  return date.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1');
}

export function dateEncode(date) {
  let timezone = date.match(/\+[0-9]{4}/g)[0];
  let hours = parseInt(timezone.slice(0, 3));
  let minutes = parseInt(timezone.slice(3, 5));
  let offset = hours + ((hours / Math.abs(hours)) * minutes) / 60;

  let newDate = new Date(parseInt(date.match(/[0-9]+/g)[0]));

  return new Date(newDate.setHours(newDate.getHours() + offset));
}

export function dateEncodeParseUtc(date) {
  return parseInt(date.match(/[0-9]+/g)[0]);
}

export function dateCompilation(date) {
  let dateCompilation: string = new Date(date).getTime().toString();

  if (new Date().toString().match(/([-+][0-9]+)/)) {
    dateCompilation += (new Date().toString().match(/([-+][0-9]+)/) || [''])[0];
  }

  return '/Date(' + dateCompilation + ')/';
}

export function dateEncodeNoUtc(date) {
  return new Date(parseInt(date.match(/[0-9]+/g)[0]));
}

export function dateCompilationNoUtc(date) {
  let dateCompilation: string = new Date(date).getTime().toString();

  dateCompilation += '+0000';

  return '/Date(' + dateCompilation + ')/';
}

export function timeCompilation(time) {
  let date = new Date();
  let timeSplit = time.split(':');

  date.setHours(parseInt(timeSplit[0]), parseInt(timeSplit[1]), 0);

  return dateCompilationNoUtc(date);
}

export function timeCompilationUtc(time) {
  let date = new Date();
  let timeSplit = time.split(':');

  date.setHours(parseInt(timeSplit[0]), parseInt(timeSplit[1]), 0);

  return dateCompilation(date);
}

export function addZero(n) {
  n = parseInt(n);
  return n < 10 ? '0' + n : n;
}

export function isEmpty(obj) {
  for (let key in obj) {
    return false;
  }
  return true;
}

/**
 *
 * @param phone {string}
 */
export function formatPhone(phone) {
  return phone.replace(/[\s()-]+/gi, '');
}

/**
 *
 * @param milliseconds {boolean}
 * @param date {Date}
 */

export function getUnixDate(
  milliseconds: boolean = false,
  date: Date = new Date()
) {
  if (milliseconds) {
    return date.getTime();
  } else {
    return (date.getTime() / 1000).toFixed(0);
  }
}
