import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import { ClinicService } from '../shared/services/clinic.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClinicInterface } from '../shared/interfaces/clinic/clinic.interface';
import { Subscription } from 'rxjs';
import { api } from '../../environments/api';
import { FindClinicResponseInterface } from '../shared/interfaces/clinic/find-clinic-response.interface';
import { CurrentClinicResponseInterface } from '../shared/interfaces/clinic/current-clinic-response.interface';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { DialogInterface } from '../shared/interfaces/dialog.interface';
import { AccountDialog } from '../account/account.component';
import { UserService } from '../shared/services/user.service';
import { CurrentUserResponseInterface } from '../shared/interfaces/user/current-user-response.interface';
import { UserInterface } from '../shared/interfaces/user/user.interface';
import { FileInterface } from '../shared/interfaces/file.interface';

@Component({
  selector: 'app-clinic',
  templateUrl: './clinic.component.html',
  styleUrls: ['./clinic.component.scss'],
})
export class ClinicComponent implements OnInit {
  preloaderVisibility: boolean = true;

  clinicsList: ClinicInterface[] = [];
  currentClinicPreInfo: ClinicInterface;
  loadDataError: boolean = false;
  clinicIndex: string;

  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;
  timeout: boolean = true;
  timeout2: boolean = true;
  timeout3: boolean = true;
  userData: UserInterface;

  avatarFiles: FileInterface[];
  avatar: string;
  avatarId: string;
  showAvatarCap: boolean = true;

  getCurrentUserData: Subscription;
  findClinicSubscribe: Subscription;
  currentClinicSubscribe: Subscription;

  constructor(
    private authService: AuthService,
    private clinicService: ClinicService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.clinicIndex = this.route.snapshot.paramMap.get('id');

    if (!this.clinicService.getClinicsListData()) {
      this.findClinicSubscribe = this.clinicService.findClinic().subscribe(
        (response: FindClinicResponseInterface) => {
          console.log('FindClinicResult', response);
          this.timeout = false;

          if (response.FindClinicResult.Success) {
            this.clinicService.setClinicsListData(
              response.FindClinicResult.AccountsData
            );
            this.clinicsList = response.FindClinicResult.AccountsData;
            this.getClinic();
          } else {
            this.openDialog(this.errorTitle, this.errorMessage, true);
          }
        },
        (error) => {
          this.timeout = false;
          this.loadDataError = true;
          this.openDialog(this.errorTitle, this.errorMessage, true);
        }
      );

      setTimeout(() => {
        if (this.timeout) {
          this.loadDataError = true;
          this.openDialog(this.errorTitle, this.errorMessage, true);
          this.findClinicSubscribe.unsubscribe();
        }
      }, 30000);
    } else {
      this.clinicsList = this.clinicService.getClinicsListData();
      this.getClinic();
    }

    if (!this.userService.getUserData()) {
      this.getCurrentUserData = this.userService
        .getCurrentUserData()
        .subscribe((response: CurrentUserResponseInterface) => {
          console.log('CurrentUser', response);

          this.timeout3 = false;

          if (response.CurrentUserResult.Success) {
            this.userService.setUserData(response.CurrentUserResult);
            this.userData = response.CurrentUserResult.UserData;
            this.getUserAvatar();
          } else {
            this.openDialog(this.errorTitle, this.errorMessage, true);
          }
        });

      setTimeout(() => {
        if (this.timeout3) {
          this.openDialog(this.errorTitle, this.errorMessage, true);
          this.getCurrentUserData.unsubscribe();
        }
      }, 30000);
    } else {
      this.getUserAvatar();
      this.userData = this.userService.getUserData().UserData;
    }
  }

  getClinic() {
    this.currentClinicPreInfo = this.clinicsList[this.clinicIndex];

    if (!this.currentClinicPreInfo) {
      setTimeout(() => {
        this.timeout = false;
        this.openDialog(
          'Такой клиники не существует!',
          'Вы пытаетесь получить доступ к несуществующей клинике.',
          true,
          'back-list'
        );
      }, 300);
      return;
    }

    this.clinicService.activeClinic = parseInt(this.clinicIndex);

    this.currentClinicSubscribe = this.clinicService
      .currentClinic(this.currentClinicPreInfo.AccountId)
      .subscribe(
        (response: CurrentClinicResponseInterface) => {
          console.log('CurrentClinicResult', response);
          this.preloaderVisibility = false;
          this.timeout2 = false;

          if (response.CurrentClinicResult.Success) {
            this.clinicService.setClinicFullData(response.CurrentClinicResult);
          } else {
            this.openDialog(this.errorTitle, this.errorMessage, true);
            this.loadDataError = true;
          }
        },
        (error) => {
          this.timeout2 = false;
          this.loadDataError = true;
          this.openDialog(this.errorTitle, this.errorMessage, true);
        }
      );

    setTimeout(() => {
      if (this.timeout2) {
        this.loadDataError = true;
        this.openDialog(this.errorTitle, this.errorMessage, true);
        this.currentClinicSubscribe.unsubscribe();
      }
    }, 30000);
  }

  getUserAvatar() {
    this.avatarFiles = this.userService.getUserData().Files;

    if (this.avatarFiles.length) {
      const last = this.avatarFiles.length - 1;

      const name = this.avatarFiles[last].Name;
      const content = this.avatarFiles[last].Content;
      this.avatarId = this.avatarFiles[last].Id;

      let filename: any = name.split('.');

      filename = filename[filename.length - 1];

      this.avatar = 'data:image/' + filename + ';base64,' + content;

      this.userService.userAvatar = this.avatar;

      this.showAvatarCap = false;
    } else {
      this.showAvatarCap = true;
    }
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null
  ) {
    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back,
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.preloaderVisibility = false;

      result == 'back-list'
        ? this.router.navigate(['/account', 'clinic-list'])
        : null;
    });
  }
}

@Component({
  selector: 'clinic-dialog',
  templateUrl: 'clinic-dialog.html',
})
export class ClinicDialog {
  constructor(
    public dialogRef: MatDialogRef<ClinicDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogInterface
  ) {}

  onNoClick() {
    this.dialogRef.close(this.data.back);
  }
}
