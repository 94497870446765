import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { AccountModule } from './account/account.module';
import { AgreementComponent } from './agreement/agreement.component';
import { CookieService } from 'ngx-cookie-service';
import { ClinicModule } from './clinic/clinic.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotFoundComponent } from './not-found/not-found.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

@NgModule({
  declarations: [AppComponent, AgreementComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    SharedModule,
    AuthModule,
    AccountModule,
    ClinicModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatPasswordStrengthModule,
  ],
  exports: [],
  providers: [CookieService],
  bootstrap: [AppComponent],
})
export class AppModule {}
