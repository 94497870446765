import { Component, OnInit } from '@angular/core';
import { ClinicService } from '../../shared/services/clinic.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit {
  constructor(public clinicService: ClinicService) {}

  ngOnInit() {}
}
