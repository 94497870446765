import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppointmentsTimeGridInterface } from '../../../interfaces/appointments/appointments-time-grid.interface';
import { SelectInterface } from '../../../interfaces/select.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClinicService } from '../../../services/clinic.service';
import { DialogService } from '../../../services/dialog.service';
import { TextMaskConfig } from 'angular2-text-mask';
import { PhoneMask } from '../../../masks/phone.mask';
import { CustomValidators } from '../../../validators/custom.validators';
import { Subscription } from 'rxjs/Subscription';
import { timeout } from 'rxjs/operators';
import { api } from '../../../../../environments/api';
import { ClinicGetUserByPhoneResponseInterface } from '../../../interfaces/clinic/schedules/clinic-get-user-by-phone-response.interface';
import { AppointmentSearchUserInterface } from '../../../interfaces/appointments/appointment-search-user.interface';
import { AppointmentAddUserDataInterface } from '../../../interfaces/appointments/appointment-add-user-data.interface';
import { AppointmentAddDataInterface } from '../../../interfaces/appointments/appointment-add-data.interface';
import { AppointmentAddResponseInterface } from '../../../interfaces/appointments/appointment-add-response.interface';
import { CatalogNamePipe } from '../../../pipes/catalog-name.pipe';
import { typeAppointment } from '../../../catalogs/clinic.catalogs';

@Component({
  selector: 'app-create-appointment-search-user',
  templateUrl: './create-appointment-search-user.component.html',
  styleUrls: ['./create-appointment-search-user.component.scss'],
})
export class CreateAppointmentSearchUserComponent implements OnInit {
  @Input() appointment: AppointmentsTimeGridInterface;
  @Input() selectServices: SelectInterface[];

  @Output() preloader = new EventEmitter<boolean>();
  @Output() setSuccess = new EventEmitter<boolean>();

  hideForm = true;
  hideAll = false;
  searchPreloader = false;
  needAddress = false;

  form: FormGroup;
  searchUserControl: FormControl;

  getUserByPhoneSubscribe: Subscription;
  createAppointmentSubscribe: Subscription;

  user: AppointmentSearchUserInterface;
  selectPets: SelectInterface[] = [];

  phoneMask: TextMaskConfig = {
    mask: PhoneMask,
  };

  constructor(
    private clinicService: ClinicService,
    private dialogService: DialogService,
    private customValidators: CustomValidators,
    private catalogNamePipe: CatalogNamePipe
  ) {}

  ngOnInit() {
    if (this.appointment.PlaceAppointmentId == typeAppointment[1].value)
      this.needAddress = true;
    this.formInit();
  }

  formInit() {
    this.searchUserControl = new FormControl(null, [
      Validators.required,
      this.customValidators.phone,
    ]);

    this.form = new FormGroup({
      pet: new FormControl(null, [Validators.required]),
      service: new FormControl(null, [Validators.required]),
      complaint: new FormControl(null, [Validators.required]),
    });

    if (this.needAddress) {
      this.form.addControl(
        'address',
        new FormControl(null, [Validators.required])
      );
    }
  }

  searchUser() {
    const phone = this.searchUserControl.value.replace(/\D+/g, '');

    this.searchPreloader = true;
    this.hideForm = true;

    this.getUserByPhoneSubscribe = this.clinicService
      .getUserByPhone(phone)
      .pipe(timeout(api.requestTimeout))
      .subscribe(
        (response: ClinicGetUserByPhoneResponseInterface[]) => {
          !api.production
            ? console.log('getUserByPhoneResponse', response)
            : null;

          this.searchPreloader = false;

          if (response.length) {
            this.user = {
              UserId: response[0].userid,
              Firstname: response[0].username,
              Surname: response[0].surname,
              Email: response[0].email,
              Phone: response[0].phone,
            };

            for (let item of response) {
              this.selectPets.push({
                value: item.petid,
                name: item.petname,
              });
            }

            this.hideForm = false;
          } else {
            this.dialogService.openStatusDialog(
              'Пользователь не найден',
              'Не удалось найти пользователя с указанным телефоном убедитесь в том что вы ввели правильно телефон или что пользователь зарегистрирован в системе'
            );
          }

          this.getUserByPhoneSubscribe.unsubscribe();
        },
        () => {
          this.searchPreloader = false;
          this.dialogService.showStandardError();
          this.getUserByPhoneSubscribe.unsubscribe();
        }
      );
  }

  submit() {
    this.preloader.emit(true);
    this.hideAll = true;

    const value = this.form.value;
    const clinicData = this.clinicService.getClinicFullData();

    const typeId = clinicData.AccClinicService.find(
      (item) => item.Id === value.service.value
    ).TypeId;

    const userData: AppointmentAddUserDataInterface = {
      UserId: this.user.UserId,
      PetId: value.pet,
      SpecialistId: this.appointment.SpecialistId,
      AccountId: this.appointment.AccountId,
    };

    let address =
      clinicData.AccountData.City + ', ' + clinicData.AccountData.Address;

    if (this.needAddress) {
      address = value.address;
    }

    const appointmentData: AppointmentAddDataInterface = {
      StartDate: this.appointment.StartDate,
      DueDate: this.appointment.DueDate,
      Compliant: value.complaint,
      ServiceId: value.service.value,
      Name: this.user.Firstname + ' ' + this.user.Surname,
      Phone: this.user.Phone,
      Email: this.user.Email,
      PetName: this.catalogNamePipe.transform(value.pet, this.selectPets),
      Price: 0,
      TypeId: typeId,
      PlaceAppointmentId: this.appointment.PlaceAppointmentId,
      Address: address,
    };

    this.createAppointmentSubscribe = this.clinicService
      .createAppointment(userData, appointmentData)
      .pipe(timeout(api.requestTimeout))
      .subscribe(
        (response: AppointmentAddResponseInterface) => {
          !api.production
            ? console.log('AppointmentAddResponseInterface', response)
            : null;

          this.preloader.emit(false);
          this.hideAll = false;

          if (response.CreateAppointmentResult.Success) {
            this.dialogService.openStatusDialog(
              'Запись на прием прошла успешно!',
              'Пользователь был успешно записан на прием в клинику.'
            );
            this.setSuccess.emit();
          } else {
            this.dialogService.showStandardError();
          }

          this.createAppointmentSubscribe.unsubscribe();
        },
        () => {
          this.preloader.emit(false);
          this.hideAll = false;
          this.dialogService.showStandardError();
          this.createAppointmentSubscribe.unsubscribe();
        }
      );
  }
}
